import React, { Component } from 'react';
import './scss/import.scss';
import Select from 'react-select';
import Async from 'react-select/async';
import { getImportedFilePreview, getCitiesSelectOptionsAsync, getCommunitiesSelectOptions, updateImportedFileRow, ImportIgnoreRow, ImportDoNotIgnoreRow, isImportedFileReady, doImportFile } from '../../webServices';
import { Field, GenericDataList } from '../Generics/GenericDataList';
import { ImCross, ImUndo, ImCheckmark } from 'react-icons/im';

class ImportedFileValidationRow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pk: -1,
            status: 0,
            firstname: "",
            lastname: "",
            email: "",
            city: null,
            community: null,
            firstnameIsValid: false,
            lastnameIsValid: false,
            emailIsValid: false,
            cityIsValid: false,
            communityIsValid: false,
        };

        this.initState = this.initState.bind(this);
        this.firstnameChanged = this.firstnameChanged.bind(this);
        this.firstnameBlur = this.firstnameBlur.bind(this);
        this.lastnameChanged = this.lastnameChanged.bind(this);
        this.lastnameBlur = this.lastnameBlur.bind(this);
        this.emailChanged = this.emailChanged.bind(this);
        this.emailBlur = this.emailBlur.bind(this);
        this.cityChanged = this.cityChanged.bind(this);
        this.communityChanged = this.communityChanged.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.componentDidUpdate = this.componentDidUpdate.bind(this);
        this.ignoreRow = this.ignoreRow.bind(this);
        this.doNotIgnoreRow = this.doNotIgnoreRow.bind(this);
        this.updateRow = this.updateRow.bind(this);
    }

    initState() {
        this.setState({
            pk: this.props.pk,
            status: this.props.data.status,
            firstname: this.props.data.firstname,
            lastname: this.props.data.lastname,
            email: this.props.data.email,
            city: this.props.data.city ? { value: this.props.data.city.pk, label: `${this.props.data.city.name} (${this.props.data.city.zip})` } : null,
            community: this.props.data.community ? { value: this.props.data.community.pk, label: this.props.data.community.name } : null,
            firstnameIsValid: this.firstnameIsValid(this.props.data.firstname),
            lastnameIsValid: this.lastnameIsValid(this.props.data.lastname),
            emailIsValid: this.emailIsValid(this.props.data.email),
            cityIsValid: this.cityIsValid(this.props.data.city),
            communityIsValid: this.communityIsValid(this.props.data.community),
            ignoreRow: this.props.data.status === 4
        });
    }

    componentDidMount() {
        this.initState();
    }

    componentDidUpdate(prevProps) {
        if (this.props.pk !== prevProps.pk) {
            this.initState();
        }
    }

    firstnameChanged(e) {
        this.setState({ firstnameIsValid: this.firstnameIsValid(e.target.value) });
    }

    firstnameBlur(e) {
        if (e.target.value === this.state.firstname) {
            return;
        }

        this.setState({ firstname: e.target.value });
        this.updateRow({ firstname: e.target.value });
    }

    lastnameChanged(e) {
        this.setState({ lastnameIsValid: this.lastnameIsValid(e.target.value) });
    }

    lastnameBlur(e) {
        if (e.target.value === this.state.lastname) {
            return;
        }

        this.setState({ lastname: e.target.value });
        this.updateRow({ lastname: e.target.value });
    }

    emailChanged(e) {
        this.setState({ emailIsValid: this.emailIsValid(e.target.value) });
    }

    emailBlur(e) {
        if (e.target.value === this.state.email) {
            return;
        }

        this.setState({ email: e.target.value });
        this.updateRow({ email: e.target.value });
    }

    cityChanged(city) {
        if (!city) {
            if (this.state.city) {
                this.setState({ city: null, cityIsValid: false });
            }
            return;
        }

        if (this.state.city && city.value === this.state.city.value) {
            return;
        }

        this.setState({ city: city, cityIsValid: this.cityIsValid(city) });
        this.updateRow({ city: city.value });
    }

    communityChanged(community) {
        if (!community) {
            if (this.state.community) {
                this.setState({ community: null, communityIsValid: false });
            }
            return;
        };

        if (this.state.community && community.value === this.state.community.value) {
            return;
        }

        this.setState({ community: community, communityIsValid: this.communityIsValid(community) });
        this.updateRow({ community: community.value });
    }

    updateRow(data) {
        updateImportedFileRow(this.props.file_pk, this.props.pk, data).then((data) => {
            if (data && data.success) {
                this.setState({ status: data.status });
                this.props.onStatusChanged();
            } else {
                //TODO
            }
        });
    }

    firstnameIsValid(firstname) {
        return firstname && firstname !== "";
    }

    lastnameIsValid(lastname) {
        return lastname && lastname !== "";
    }

    emailIsValid(email) {
        // const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return email && regex.test(email) !== false;
    }

    cityIsValid(city) {
        return city != null;
    }

    communityIsValid(community) {
        return community != null;
    }

    ignoreRow() {
        ImportIgnoreRow(this.props.file_pk, this.state.pk).then((data) => {
            if (data && data.success) {
                this.setState({ ignoreRow: true, status: data.status });
                this.props.onStatusChanged();
            }
        });
    }

    doNotIgnoreRow() {
        ImportDoNotIgnoreRow(this.props.file_pk, this.state.pk).then((data) => {
            if (data && data.success) {
                this.setState({ ignoreRow: false, status: data.status });
                this.props.onStatusChanged();
            }
        });
    }

    render() {
        const { data, community_select_opt } = this.props;
        
        let city_placeholder = '';

        if (data.file_values.city) {
            city_placeholder += data.file_values.city;
        }

        if (data.file_values.zip) {
            city_placeholder += ' (' + data.file_values.zip + ')';
        }

        let title = "";
        for (let label in data.file_row) {
            title += label + ": " + data.file_row[label] + '\n';
        }

        let status = <div></div>;
        if (this.state.status === 2) {
            status = <ImCheckmark />;
        }

        return (
            <>
                <tr className={`table_box ${this.state.ignoreRow ? 'disabled' : ''}`} key={this.state.pk} title={title}>
                    <td>{status}</td>
                    <td className="text-center"><input type="text" className={`row-input ${!this.state.firstnameIsValid ? 'invalid' : ''}`} defaultValue={this.state.firstname} placeholder={data.file_values.firstname} onChange={this.firstnameChanged} onBlur={this.firstnameBlur} disabled={this.state.ignoreRow} /></td>
                    <td className="text-center"><input type="text" className={`row-input ${!this.state.lastnameIsValid ? 'invalid' : ''}`} defaultValue={this.state.lastname} placeholder={data.file_values.lastname} onChange={this.lastnameChanged} onBlur={this.lastnameBlur} disabled={this.state.ignoreRow} /></td>
                    <td className="text-center" ><input type="email" className={`row-input ${!this.state.emailIsValid ? 'invalid' : ''}`} defaultValue={this.state.email} placeholder={data.file_values.email} onChange={this.emailChanged} onBlur={this.emailBlur} disabled={this.state.ignoreRow} /></td>
                    <td className="text-center"><Async name="city" className={`${!this.state.cityIsValid ? 'invalid' : ''}`} defaultValue={this.state.city} defaultOptions={this.state.city ? [this.state.city] : null} loadOptions={getCitiesSelectOptionsAsync} placeholder={city_placeholder} noOptionsMessage={() => { return "Tapez le nom de la ville ou son code postal"; }} isClearable={true} onChange={this.cityChanged} isDisabled={this.state.ignoreRow} /></td>
                    <td className="text-center"><Select name="community" className={`${!this.state.communityIsValid ? 'invalid' : ''}`} defaultValue={this.state.community} options={community_select_opt} placeholder={data.file_values.community} isClearable={true} onChange={this.communityChanged} isDisabled={this.state.ignoreRow} /></td>
                    <td>{this.state.ignoreRow ? <button className="btn btn-lg btn-light" title="ne plus ignorer la ligne" onClick={this.doNotIgnoreRow}><ImUndo /> </button> : <button className="btn btn-lg btn-danger" title="ignorer la ligne" onClick={this.ignoreRow}><ImCross /></button>}</td>
                </tr>
            </>
        );
    }
}

class ImportValidation extends GenericDataList {

    constructor(props) {
        super(props, 50, "pk", true);
        this.onStatusChanged = this.onStatusChanged.bind(this);
        this.loadData = async (params = {}) => { return await getImportedFilePreview(this.props.pk, params) };
        this.columns = [
            new Field("status", ""),
            new Field("firstname", "Prénom", "text-center", true),
            new Field("lastname", "Nom de famille", "text-center", true),
            new Field("email", "Email", "text-center", true),
            new Field("city__name", "Ville", "text-center", true),
            new Field("community__name", "Communauté", "text-center", true),
            new Field("", "Action")
        ];

        this.doImport = this.doImport.bind(this);
    }

    get_data_row(data, index) {
        return <ImportedFileValidationRow
            key={index}
            file_pk={this.props.pk}
            pk={data.pk}
            data={data}
            community_select_opt={this.community_select_opt}
            onStatusChanged={this.onStatusChanged}
        />;
    }

    async doImport() {
        let success = await doImportFile(this.props.pk);
        if (success) {
            this.props.onFileImported();
        }
    }

    async onStatusChanged() {
        let import_ready = await isImportedFileReady(this.props.pk);
        this.setState({ import_ready: import_ready });
    }

    async componentDidMount() {
        await super.componentDidMount();
        this.community_select_opt = await getCommunitiesSelectOptions();
        let import_ready = await isImportedFileReady(this.props.pk);
        this.setState({ import_ready: import_ready });
    }

    render() {
        let import_btn_class = this.state.import_ready ? 'btn btn-lg btn-success' : 'btn btn-lg btn-success disabled';

        return <div className="">
            <div className="container-fluid">
                <div className="nk-content-inner">
                    <div className="nk-content-body">
                        <div className="nk-block">
                            <div className="row g-gs">
                                <div className="title">
                                    <button type="submit" className={import_btn_class} onClick={this.doImport} title={this.state.import_ready ? '' : 'Corriger les champs en rouge pour finaliser importer'}> Finaliser l'import du fichier </button>
                                </div>
                                { super.render() }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }
}

export default ImportValidation;