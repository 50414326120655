import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import config from './config'
import { AuthProvider,AuthService } from 'react-oauth2-pkce';
import reportWebVitals from './reportWebVitals';

const authService = new AuthService({
  authorizeEndpoint: config.REACT_APP_AUTHORIZE_ENDPOINT,
  tokenEndpoint: config.REACT_APP_TOKEN_ENDPOINT,
  clientId: config.REACT_APP_CLIENT_ID,
  clientSecret: config.REACT_APP_CLIENT_SECRET,
  scopes: ['admin','read','write','authenticated'],
  redirectUri: config.REACT_APP_REDIRECT_URI,
  location: config.REACT_APP_LOCATION,
  autoRefresh: false,
})

ReactDOM.render(
  <AuthProvider authService={authService}>
    <App />
  </AuthProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
