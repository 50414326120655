import React, { Component } from "react";
import Map from "../../../Map/Map";

class MapWidget extends Component {
  render() {
    const {alert, className} = this.props;

    return (
      <div className={className} id={alert.id}>
        <Map whole_dep={alert.whole_dep} city={alert.city} range={alert.range} mapId='mapwidget' heightREM="22.854" ShowMapInfo={true} />
      </div>
    );
  }
}

export default MapWidget;
