import React from "react";
import config from "../../config";
import './Infomap.scss';

let Perimetre = props => {
  let { range } = props;
  if (typeof range !== "number") { return null; }

  return (
    <span className="range Range_style">
    <strong> { config.DEPARTEMENT } </strong> - Dans un périmètre de {range} KM 
    </span>
  );
};

const InfoMap = props => {
  let { city, range, show, stylecss } = props;
  if (!show) {
    return null;
  }

  return (
    <div className="banner card-footer truc_footer" style={stylecss}>
      <div className="container">
        <div className="row">

          <div className="col">
            <div className="align-items-start cityTile City_title" style={{fontSize:'14px'}}>
              {city ? city.name : 'Tout le département'} <Perimetre range={range}/>
            </div>
          </div>

          <div className="col-3">
            <div className="align-items-end">
              <strong className="regions"> Région: { config.REGION }</strong>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};

export default InfoMap;