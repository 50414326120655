import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import 'react-custom-scroll/dist/customScroll.css';
import {getImportedFiles, cancelImport} from '../../webServices'
import {Field, GenericDataList} from '../Generics/GenericDataList'

class ImportedFileRow extends Component {
  constructor(props) {
      super(props);
      this.state = {};
      this.cancelImport = this.cancelImport.bind(this);
  }

  async cancelImport() {
    let success = cancelImport(this.props.pk);
    if (success) {
        //Reload After Post 
        setTimeout(() => {
            window.location.reload();
        }, 500);
    }
  }

  render() {
    const { pk, file_name, import_date, status, continueImport} = this.props;
    let action_btn = status < 2 ?
    <>
    {/* <Link to="#" onClick={() => { continueImport(pk, status); }} title="Continuer l'import"> Continuer </Link>  */}
    {/* <Link to="#" className="cancel-link" onClick={this.cancelImport} title="Annuler l'import"> Annuler </Link> */}

    <Link to="#" className="btn btn-trigger btn-icon" onClick={() => { continueImport(pk, status); }} title="Continuer l'import" > <em className="icon ni ni-edit-alt edito"></em> </Link>
    <Link to="#" className="btn btn-trigger btn-icon" onClick={this.cancelImport} data-toggle="tooltip" data-placement="top" title="Annuler"><em className="icon ni ni-cross cross_import "></em> </Link>
    </>
    : null;

    let status_label = "";
    status_label = switchCase(status, status_label);

    return (
        <tr className="table_box" key={pk}>

            <td className="text-left"> {file_name} </td>

            <td className="text-center" >
                {
                    new Date(import_date * 1000).toLocaleDateString('fr-FR', {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                        hour: '2-digit',
                        minute: '2-digit'
                    })
                }
            </td>

            <td className="text-center"> {status_label} </td>

                <td className="nk-tb-col nk-tb-col-tools">
                    <ul className="nk-tb-actions gx-1 containte">
                        <li className="nk-tb-action"> {action_btn} </li>
                    </ul>
                </td>

            {/* <td className="text-center"> {action_btn} </td> */}
          </tr>
        );
    }
}

class ListImportedFiles extends GenericDataList {
  constructor(props) {
    super(props, 7, "-import_date");
    this.loadData = getImportedFiles;
    this.columns = [ new Field("file_name", "Nom du fichier", true, true, "text-left"), new Field("import_date", "Date de l'import", true, false, "text-center"), new Field("status", "Status", true, false, "text-center"), new Field("", "Action")];
  }

  get_data_row(data,index) {
    const { pk, file_name, import_date, status} = data;
    return (
        <ImportedFileRow
            file_name={file_name}
            import_date={import_date}
            status={status}
            pk={pk}
            key={index}
            continueImport={this.props.continueImport}
        />
    );
  }

  render() {
    return <div className="">
      <div className="container-fluid">
          <div className="nk-content-inner">
              <div className="nk-content-body">
                  <div className="nk-block">
                      <div className="row g-gs">

                            <div className="col-md-3 marginn"> 
                                <h4 className="history"> Fichiers Importés  </h4>
                            </div>

                            { super.render() }

                      </div>
                  </div>
              </div>
          </div>
      </div>
    </div>;
  }
}
export default ListImportedFiles

function switchCase(status, status_label) {
    switch (status) {
        case 0:
        case 1:
            status_label = <span className="badge badge-dot badge-warning point"> En Cours </span> ;
            break;

        case 2:
            status_label = <span className="badge badge-dot badge-success point"> Terminé </span> ;
            break;

        case 3:
            status_label = <span className="badge badge-dot badge-danger point"> Annulé </span>;
            break;

        default:

    }
    return status_label;
}
