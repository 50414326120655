import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import Mainheader from "../../Header";
import swal from 'sweetalert';
import CustomScroll from 'react-custom-scroll';
import logo from '../../../img/logo-alerte-70-header.png';
import AlertCard from "./AlertCard";

//import refresher from '../../../img/path.svg';

/** Widget */
import DetailWidget from "./Widget/DetailWidget";
import CreationdateWidget from "./Widget/CreationdateWidget";
import OpeningWidget from "./Widget/OpeningWidget";
import ResponseWidget from "./Widget/ResponseWidget";
import ChartWidget from "./Widget/ChartWidget";
import MapWidget from "./Widget/MapWidget";
/** Widget */

import { getActiveAlerts, getInactiveAlerts, /** closeAlert */} from '../../webServices';
import './scss/alert.scss'
import NoAlert from "../../Errors/NoAlert";
//import Modal from "../Generics/Modal";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: null,
      message: "",
      alerts_active: [],
      alerts_inactive: [],
      loaded: false,
    };

    this.controller = null;
    this.rangess = 10;
    this.slotss = 6;
    this.refresh = this.refresh.bind(this);
  }
  
  //refresh
  async refresh(e, alert) {
    const data = await getActiveAlerts({ 'paginate-by': 0 }, alert);
    const data_inactive = await getInactiveAlerts({ 'paginate-by': 2 });
    
    swal({
        title: 'Alertes Mis à jour',
        text: e.response,
        icon: "success",
        timer: 3000
      });
      

    if (data && data.success) {
      this.setState({
        alerts_active: data.items,
        inactive_alertes:data_inactive.items
      });
    }
    else {
      swal({
        title: 'Erreur',
        text: e.message,
        icon: "error",
        timer: 3000
      });
    }

  }

  // Parcourir les alertes 
  handleClickAlert(e, alert) {
    if (e.currentTarget.classList.contains('active')) { return false; }

    const active = document.getElementsByClassName('active card-bordered')[0];

    if (active) {
      active.classList.remove('active');
    }

    this.setState({
      alert: alert,
    });

  };

  // 
  async componentDidMount(id) {
    const active_alertes = await getActiveAlerts({ 'paginate-by': 0 });
    const inactive_alertes = await getInactiveAlerts({ 'paginate-by': 2 });

    this.setState({
      alert: id ? active_alertes.items[id] : active_alertes.items[0],
      alerts_active: active_alertes.items,
      alerts_inactive: inactive_alertes.items,
      loaded: true
    });

  }

  //
  async componentDidUpdate(prevProps, prevState, snapshot) {
    if ((prevState.alert && this.state.alert && prevState.alert.id !== this.state.alert.id) || (prevState.alert && !this.state.alert)) {

      //alertes actives
      const active_alertes = await getActiveAlerts({ 'paginate-by': 0 });

      //alertes inactives
      const inactive_alertes = await getInactiveAlerts({ 'paginate-by': 2 });

      this.setState({
        alerts_active: active_alertes.items,
        alerts_inactive: inactive_alertes.items
      });

    }
  }

  //
  componentWillUnmount() {
    clearTimeout(this.refresher);
  }

  render() {
    const { alerts_active, alerts_inactive } = this.state;
    return (
      <Fragment>

        <div className="nk-app-root">
          {/* <!-- main @s --> */}
          <div className="nk-main">
            {/* <!-- sidebar @s --> */}
            <div className="nk-sidebar nk-sidebar-fixed is-dark d " data-content="sidebarMenu">
              <div className="nk-sidebar-element nk-sidebar-head">
                <div className="nk-menu-trigger">
                  <Link to="/" className="nk-nav-toggle nk-quick-nav-icon d-xl-none" data-target="sidebarMenu"><em className="icon ni ni-arrow-left" /></Link>
                  <Link to="/" className="nk-nav-compact nk-quick-nav-icon d-none d-xl-inline-flex" data-target="sidebarMenu"><em className="icon ni ni-menu" /></Link>
                </div>
                <div className="nk-sidebar-brand">
                  <Link to="/" className="logo-link nk-sidebar-logo">
                    <img className="logo-light logo-img" src={logo} srcSet={logo} width={150} alt="logo" />
                    <img className="logo-dark logo-img" src={logo} srcSet={logo} width={150} alt="logo-dark" />
                  </Link>
                </div>
              </div>
              {/* <!-- .nk-sidebar-element --> */}
              <div className="nk-sidebar-element nk-sidebar-body">
                <div className="nk-sidebar-content">
                  <div className=" nk-sidebar-menu ">

                    <div className="nk-menu">

                      {/* <!-- .alertes_en_cours --> */}
                      <div id="alerte_en_cours" className={'alert-group'}>
                        <span className="nk-menu-heading">
                          <h6 className="overline-title text-primary-alt marge "> &nbsp; &nbsp; &nbsp;  Alertes en Cours <em className="icon-refresh refresher" type="button" onClick={this.refresh}></em> </h6>
                        </span>

                        <CustomScroll allowOuterScroll={true} heightRelativeToParent={'calc(100% - 2.6rem)'}>
                          {

                            alerts_active == null ? null : alerts_active.map((alert) => {
                              const { id, title, creation_date, description, has_image, communities, plaintext_description } = alert;
                              return (
                                <AlertCard
                                  active={this.state.alert && id === this.state.alert.id}
                                  creation_date={creation_date}
                                  key={id}
                                  id={id}
                                  title={title}
                                  description={description}
                                  plaintext_description={plaintext_description}
                                  has_image={has_image}
                                  communities={communities}
                                  onClick={(e) => this.handleClickAlert(e, alert)}
                                />
                              );
                            })

                          }
                        </CustomScroll>


                        {/* <!-- .nk-menu-item --> */}
                      </div>
                      {/* <!-- .alertes_en_cours --> */}

                      {/* <!-- .alertes_archivées --> */}
                      <div id="alerte_archivees" className={'alert-group'}>
                        <span className="nk-menu-heading">
                          <h6 className="overline-title text-primary-alt">  &nbsp; &nbsp; &nbsp; Historique des Alertes </h6>
                          <br />
                        </span>

                        {

                          alerts_inactive == null ? null : alerts_inactive.map((alert, index) => {
                            const { id, title, creation_date, description, has_image, communities, plaintext_description } = alert;
                            return (
                              <AlertCard
                                active={false}
                                creation_date={creation_date}
                                key={index}
                                id={id}
                                title={title}
                                description={description}
                                plaintext_description={plaintext_description}
                                has_image={has_image}
                                communities={communities}
                              />
                            );
                          })
                          
                        }

                      </div>
                      {/* <!-- .alertes_archivées --> */}

                    </div>

                    {/* <!-- .nk-menu --> */}
                  </div>
                  {/* <!-- .nk-sidebar-menu --> */}
                </div>
                {/* <!-- .nk-sidebar-content --> */}
              </div>

              {/* <!-- .nk-sidebar-element --> */}
            </div>
            {/* <!-- sidebar @e --> */}

            {/* <!-- wrap @s --> */}
            <div className="nk-wrap ">
              {/* <!-- main header @s --> */}

              <Mainheader />

              {/* <!-- main header @e --> */}

              {/* <!-- content @s --> */}

              <div className="nk-content h-100">
                <div className="container-fluid">
                  <div className="nk-content-inner">
                    <div className="nk-content-body">
                      <div className="nk-block">
                        <div className="row g-gs">
                          
                          {

                            this.state.loaded && this.state.alert == null ? <div className="col-md-12"> <NoAlert/> </div> : 

                            <>

                              <div className="col-xs-12 col-sm-3 col-md-3">

                                {
                                  this.state.alert == null ? null : <DetailWidget alert={this.state.alert} />
                                } 

                                {
                                  this.state.alert == null ? null : <OpeningWidget alert={this.state.alert} />
                                }

                              </div>

                              <div className="col-xs-12 col-sm-6 col-md-6">

                                {
                                  this.state.alert == null ? null : <MapWidget id="map" className="google-map border-0 " alert={this.state.alert} ShowInformation={true} />
                                }

                                {
                                  this.state.alert == null ? null : <ChartWidget alert={this.state.alert} />
                                }

                              </div>

                              <div className="col-xs-12 col-sm-3 col-md-3">

                                {
                                  this.state.alert == null ? null : <CreationdateWidget alert={this.state.alert} />
                                }

                                {
                                  this.state.alert == null ? null : <ResponseWidget alert={this.state.alert} />
                                }

                              </div>

                            </>

                          }

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- wrap @e --> */}
          </div>
          {/* <!-- main @e --> */}
        </div>
        {/* <!-- app-root @e --> */}

      </Fragment>
    );
  }
}

export default Dashboard;
