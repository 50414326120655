import React, { Component } from "react";
import Button from "../../Generics/Button";
import AlertModal from '../../Generics/AlertModal';
import ClosedMessage from "../../Generics/ClosedMessage";

import box from '../../../images/box.svg';
import { getAllCommunities, getAllCities, getAllCategories } from '../../../webServices';

class CreationdateWidget extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showAddAlert: false,
      showArchive: false,
      is_loading: false,
      isOn: true,
      check: false,
      selectedCity: null,
      selectedCommunities: [],
      selectedCategory: null,
      markerLocation: [47.6166, 6.1530],
    };

    this.handlePerimeterModify = this.handlePerimeterModify.bind(this);
    this.handleRangeModify = this.handleRangeModify.bind(this);
    this.handleModifCom = this.handleModifCom.bind(this);
    this.handleModifCat = this.handleModifCat.bind(this);
    this.handleModifCity = this.handleModifCity.bind(this);
    this.myref = React.createRef();
    this.showAlerteModal = this.showAlerteModal.bind(this);
    this.hideAlertModal = this.hideAlertModal.bind(this);
    this.showArchiveModal = this.showArchiveModal.bind(this);
    this.hideArchiveModal = this.hideArchiveModal.bind(this);
    this.categoryRef = React.createRef();
    this.communeRef = React.createRef();
  }

  //handle modification Communautés
  handleModifCom(e) {

    const community = this.myref.current.value;

    let selectedCommunities = this.state.selectedCommunities;

    if (selectedCommunities.includes(community)) {
      selectedCommunities = selectedCommunities.filter(function (value, index, arr) {
        return value !== community;
      });
    }

    else {
      selectedCommunities.push(this.myref.current.value);
    }

    this.setState({
      selectedCommunities: selectedCommunities
    });
  }

  //handle Modification Categorie
  handleModifCat(e) {
    const category = this.categoryRef.current.value;

    let selectedCategory = this.state.selectedCategory;

    if (selectedCategory.includes(category)) {
      selectedCategory = selectedCategory.filter(function (value, index, arr) {
        return value !== category;
      });
    }
    else {
      selectedCategory = category;
    }

    this.setState({
      selectedCategory: selectedCategory
    });
  }

  //handle Modification Commune
  handleModifCity(e) {
    const selectedciity = e.target.value;
    const city2 = this.state.city_active.find(c => c.name === selectedciity);

    this.setState({
      city: city2
    });
  }

  //handle whole_dep 
  handlePerimeterModify() {
    this.setState(prevState => (
      {
        check: !prevState.check,
      }
    ));

    if (this.state.whole_dep === true) {
      this.showHideName = 'display-block';
      this.setState({
        whole_dep: false
      });
    }
    else {
      this.showHideName = 'display-none';
      this.setState({
        whole_dep: true
      });
    }

    this.setState(state => ({
      isOn: !state.isOn
    }));
  }

  //handle range modification
  handleRangeModify(e) {
    const circonference = e.target.value;

    this.setState({
      range: circonference
    });
  }

  async componentDidMount() {
    const communities = await getAllCommunities();//TODO: do not retrive all communities each time the header is displayed...
    const cities = await getAllCities();//TODO: do not retrive all cities each time the header is displayed...
    const categories = await getAllCategories();//TODO: do not retrive all categories each time the header is displayed...

    this.setState({
      commu_active: communities,
      city_active: cities,
      categories_active: categories,
    });
  }

  //showAlerteModal
  showAlerteModal() {
    this.setState({ showAddAlert: true});
  }

  //hideAlertModal
  hideAlertModal() {
    this.setState({ showAddAlert: false });
  }

  showArchiveModal() {
    this.setState({ showArchive: true});
  }

  hideArchiveModal() {
    this.setState({ showArchive: false});
  }

  render() {
    const { alert } = this.props;
    const date_creation = new Date(alert.creation_date * 1000);

    return (
      <div className="card card-bordered card-full creation_ty eval">
        <div className="card-inner perso_taille">
          <div className="project">

            <div className="project-head">
              <div className="project-title" >

                <div className="project-info">
                  <h5 className="dat_"> Date de création </h5>
                  <h6 className="title title_crea">

                    {
                      `${alert.creation_date ? date_creation.toLocaleDateString('fr-FR', {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric'
                      }) : ''}`
                    }

                  </h6>
                  <span className="badge badge-dim badge-warning badg">
                    <span>
                      {`à ${alert.creation_date ? date_creation.toLocaleTimeString('fr-FR', {
                        hour: '2-digit',
                        minute: '2-digit'
                      }) : ''}`}
                    </span>
                  </span>
                </div>
              </div>
            </div>

            <div className="btn-toolbar center_group">
              <div id="archiver" className="btn-group truc_group">
                <Button handleClick={this.showArchiveModal} >
                  <img src={box} srcSet={box} alt='Box' /> Archiver l'alerte
                </Button>
              </div>

              <div id="modifier" className="btn-group truc_group">
                <Button handleClick={this.showAlerteModal} >
                  Modifier l'alerte
                </Button>
              </div>
            </div>

            <AlertModal
              show={this.state.showAddAlert}
              handleClose={this.hideAlertModal}
              title="Modifier l'alerte"
              alert={alert}
            />

            <ClosedMessage
              show={this.state.showArchive}
              handleClose={this.hideArchiveModal}
              title="Message de Clôture"
              alert={alert}

            />

          </div>
        </div>
      </div>
    );
  }
}

export default CreationdateWidget;
