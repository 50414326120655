import React, { Component } from 'react'
import Loading from "../../Loading";
import './scss/import.scss';
import Select from 'react-select'
import swal from 'sweetalert';
import Async from 'react-select/async';
import { getCitiesSelectOptionsAsync, getCommunitiesSelectOptions, getImportedFile, setColumnMapping } from '../../webServices'

class ColumnsMapping extends Component {
    constructor(props) {
        super(props);
        this.state = {
            is_loaded:false,
        };

        this.setStateFromData = this.setStateFromData.bind(this);
        this.getSelectColunmMapping = this.getSelectColunmMapping.bind(this);
        this.validateColumnsMapping = this.validateColumnsMapping.bind(this);
    }

    async componentDidMount() {
        if ('file_data' in this.props && this.props.file_data) {
            //TODO: init values with props data
            this.setStateFromData(this.props.file_data);
        }
        else if ('pk' in this.props) {
            //Init file data with api call
            let data = await getImportedFile(this.props.pk);
            this.setStateFromData(data.item);
        }

        let all_communities_select_opt = await getCommunitiesSelectOptions();
        this.setState({all_communities_select_opt: all_communities_select_opt});
    }

    setStateFromData(data) {
        let field_list = [],
            mapping = [];

        for (let i=0; i < data.columns_header.length; ++i) {
            mapping.push("");
        }

        for (let key in data.columns_mapping) {
            field_list.push(key);
            let id = data.columns_mapping[key];
            if (id >= 0) {
                mapping[data.columns_mapping[key]] = key;
            }
        }

        this.setState({
            is_loaded: true,
            pk: data.pk,
            file_name: data.file_name,
            headers: data.columns_header,
            rows: data.first_rows,
            nb_rows: data.nb_rows,
            field_list: field_list,
            mapping: mapping
        });
    }

    getColumnLabel(col_name) {
        switch(col_name) {
            case 'firstname':
                return 'Prénom';
            case 'lastname':
                return 'Nom de famille';
            case 'email':
                return 'Email';
            case 'community':
                return 'Communauté';
            case 'zip':
                return 'Code postal';
            case 'cityname':
                return 'Ville';
                
            /** Modification */    
            default:
        }
        return '';
    }

    getSelectColunmMapping(col_i) {
        let options = [];
        let default_value = null;

        for (let i in this.state.field_list) {

            let name = this.state.field_list[i];
            let option = { value: name, label: this.getColumnLabel(name) };

            if (name === this.state.mapping[col_i]) {
                default_value = option;
            }

            options.push(option);
        }

        return <Select
                name={"mapping_col_"+col_i}
                defaultValue={default_value}
                placeholder="Aucune correspondance"
                options={options}
                isClearable={true} 
                />;
    }

    validateColumnsMapping(e) {
        e.preventDefault();

        let bodyFormData = new FormData();
        bodyFormData.set('auto_fill_fields', 1);
        bodyFormData.set('remove_empty_rows', 1);

        if (e.target.default_community.value) bodyFormData.set('default_community', e.target.default_community.value);
        if (e.target.default_city.value) bodyFormData.set('default_city', e.target.default_city.value);

        for (let i in this.state.field_list) {
            bodyFormData.append(this.state.field_list[i]+'_col', -1);
        }

        for (let i=0; i < this.state.headers.length; ++i) {
            let field_name = e.target['mapping_col_'+i].value;
            if (!field_name) {
                continue;
            }

            // eslint-disable-next-line eqeqeq
            if (bodyFormData.get(field_name+'_col') != -1) {
                swal({
                    title: 'Erreur',
                    text: `La colonne ${this.getColumnLabel(field_name)} a été spécifié plusieurs fois`,
                    icon: "error",
                });
                return;
            }

            bodyFormData.set(field_name+'_col', i);
        }

        setColumnMapping(this.state.pk, bodyFormData)
        .then(data => {
            if (data && data.success) {
                if (typeof this.props.onColumnsMapped === 'function') {
                    this.props.onColumnsMapped(this.state.pk);
                }
            } 
            else {
                //TODO
            }
        });
    }

    render() {
        if (!this.state.is_loaded) {
          return (
            <div> <Loading /> </div>
          );
        }
    
        //TODO: ajouter un bouton annuler l'import -> si mauvaise config lors de l'import du fichier par exemple -> dans ce cas, si et seulement si aucune donnée n'est importé -> supprimer les data de la base
        //TODO: attention: une mapping ne peut etre choisi deux fois pour deux colonnes différentes

        return (
            <div className="col-md-12">
                <form onSubmit={this.validateColumnsMapping}>
                    <h4 style={{ textTransform: 'uppercase' }}> {this.state.file_name} </h4>
                    <br/>
                    <div className="row">

                        <div className="col-sm">
                            <h5 className="display-h5">Communauté par default: </h5>

                            <div className="col-sm">
                            <Select 
                                name="default_community" 
                                options={this.state.all_communities_select_opt} 
                                isClearable={true} 
                                placeholder="Communauté par défaut"
                            />
                            </div>
                        </div>

                        <div className="col-sm">
                            <h5 className="display-h5">Ville par default: </h5>

                            <div className="col-sm">
                            <Async 
                                name="default_city" 
                                loadOptions={getCitiesSelectOptionsAsync} 
                                isClearable={true} 
                                placeholder="Ville par défaut" 
                                noOptionsMessage={() => { return "Tapez le nom de la ville ou son code postal"; }} 
                            />
                            </div>
                        </div>
                    </div>

                    <br/>

                    <h5 className="display-h5"> Vérifiez / Selectionnez la correspondance des colonnes: </h5>
                    <div className='table_wrapper'>
                    <table className="table excel_preview">
                        <thead>
                            <tr className="table_row">
                                <th></th>
                                {this.state.headers.map((name, i) => {
                                    return <th className="excel_value text-left" key={i}>{name}</th>;
                                })}

                            </tr>

                            <tr>
                                <th></th>
                                {this.state.headers.map((name, i) => {
                                    return <th className="excel_value" key={i}>{this.getSelectColunmMapping(i)}</th>;
                                })}
                            </tr>

                        </thead>
                        <tbody>
                            {
                                this.state.rows.map((row, i) => {
                                    return <tr key={i}>
                                        <th className='text-center'>{i+1}</th>
                                        {
                                            row.map((value, i) => {
                                                return <td className="text-left excel_value" key={i}> {value} </td>;
                                            })
                                        }
                                    </tr>;
                                })
                            } 

                            {
                                this.state.nb_rows > this.state.rows.length ? 
                                    <tr>
                                        <th className='text-center'>...</th>
                                        <th className='text-left' colSpan={this.state.headers.length}> + {this.state.nb_rows - this.state.rows.length} lignes suplémentaires </th>
                                    </tr> : null
                            }
                        </tbody>
                    </table>
                    </div>
                    <button type="submit" className="btn btn-lg btn-success"> Valider la correspondance des colonnes </button>
                </form>
            </div>
        );
    }
}

export default ColumnsMapping;