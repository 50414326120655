import React, { Component } from "react";
import L from 'leaflet';
import Departement from './geo.json';
import 'leaflet/dist/leaflet.css';
import InfoMap from "./Infomap";

class Map extends Component {
  constructor(props) {
    super(props);

    this.depCenterCoordinates = [47.6234719, 6.1559043];
    this.fillBlueOptions = { fillColor: '#0062FF' };
    
    //iconmarker
    this.iconDiv = L.divIcon({
        className: 'custom-icon',
        html: '<div className=\'dot\'></div>',
        iconSize: [18, 18],
        iconAnchor: [10, 10],
    });

    this.cityMarker = L.marker(this.depCenterCoordinates, { icon: this.iconDiv });
    this.randgeRadius = L.circle(this.depCenterCoordinates, 0);
    this.depOutline = L.geoJSON(Departement);

    this.initMap = this.initMap.bind(this);
    this.resetMap = this.resetMap.bind(this);
    this.updateLayers = this.updateLayers.bind(this);
  }

  initMap(center, zoom) {
    if (this.map) {
      this.map.remove();
    }

    this.map = L.map(this.props.mapId, { zoomControl: false }).setView(center, zoom);

    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>',
    }).addTo(this.map);
  }

  resetMap() {
    this.initMap(this.depCenterCoordinates, 8);
    this.updateLayers();
  }

  updateLayers() {
    const {whole_dep, city, range} = this.props;

    this.map.removeLayer(this.cityMarker);
    this.map.removeLayer(this.randgeRadius);
    this.map.removeLayer(this.depOutline);

    if (whole_dep) {
      this.map.addLayer(this.depOutline);
      this.map.setView(this.depCenterCoordinates, 8);
      return;
    }

    const coord = city === null ? this.depCenterCoordinates : [city.coord.lat, city.coord.lon];
    const zoom =  range > 0 ? parseInt(Math.log2(256/range)) + 6 : 13;

    this.cityMarker = L.marker(coord, { icon: this.iconDiv });
    this.randgeRadius = L.circle(coord, range * 1000 || 0);

    this.map.addLayer(this.cityMarker);
    this.map.addLayer(this.randgeRadius);
    this.map.setView(coord, zoom);
  }

  //
  async componentDidMount() {
    //Initialisation de la map
    this.resetMap();
  }

  //
  componentDidUpdate(prevProps, prevState, snapshot) {
    //TODO: check that React.memo update this component only if props changed !!
    if (prevProps.visible !== this.props.visible && this.props.visible) {
      this.resetMap();
      return;
    }

    if (prevProps.whole_dep !== this.props.whole_dep || prevProps.city !== this.props.city || prevProps.radius !== this.props.radius) {
      this.updateLayers();
    }
  }

  styleMapCust() {
    if (this.props.ShowMapInfo) {
      return  {
        width: "auto",
        height: (this.props.heightREM - 5) + 'rem',
        borderRadius: `1.425rem 1.425rem 0 0`,
      };
    }
    return {
      width: "auto",
      height: this.props.heightREM + 'rem',
      borderRadius: `1.425rem`,
    };
  };


  render() {
    const {ShowMapInfo, whole_dep, city, range} = this.props;
    const Info = () => {
      if (whole_dep) {
        return <InfoMap show={ShowMapInfo} />;
      }

      return <InfoMap show={ShowMapInfo} city={city} range={range} />
    }

    return <>
      <div style={this.styleMapCust()} id={this.props.mapId} className="MapContent"></div>
      <Info />
    </>;
  }
}

export default Map;