import React, { Component } from 'react';
import { importFile } from '../../webServices';
import Modal from "../Generics/Modal";

class ImportFileModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            show: false,
            file_name: "",
            file_ext: "",
        };

        this.show = this.show.bind(this);
        this.hide = this.hide.bind(this);
        this.uploadFile = this.uploadFile.bind(this);
        this.handleFileChange = this.handleFileChange.bind(this);
    }

    show() {
        this.setState({show: true});
    }

    hide() {
        this.setState({
            show: false,
            file_name: "",
            file_ext: "",
        });
    }

    handleFileChange(selectorFiles = FileList) {
        if (selectorFiles.length !== 1) {
            return;
        }

        let file = selectorFiles[0];
        let splitted_name = file.name.split('.');

        if (splitted_name.length < 2) {
            return;
        }

        let file_ext = splitted_name[splitted_name.length-1];
        this.setState({file_name: file.name,file_ext: file_ext});
    }

    uploadFile (e) {
        e.preventDefault();

        let bodyFormData = new FormData();
        let header = parseInt(e.target.header.value);
        //if (header != NaN && header > 0)

        if (header !== null && header > 0) {
            bodyFormData.append('header',  header - 1);
        }
        
        if (this.state.file_ext === "csv") {
            bodyFormData.append('csv_sep', e.target.csv_sep.value);
        }

        bodyFormData.append('file', e.target.file.files[0]);

        importFile(bodyFormData)
        .then(data => {
            //hide modal after Posting Alert
            if (data && data.success) {
                this.hide();
                e.target.reset();

                if (typeof this.props.onFileUploaded === 'function') {
                    this.props.onFileUploaded(data.file);
                }
            } 
            else {
                 //TODO: manage error....
            } 
        });
    };

    render() {
        const enableCsvFields = this.state.file_ext === 'csv';

        return (
            <Modal show={this.state.show} handleClose={this.hide} title="Importer un fichier">
                <div className="card-inner">
                    <form onSubmit={this.uploadFile} id="import-file-form" className='popupForm'>
                        <div className='form-group'>
                            <fieldset>
                                <div>
                                    <label id="select-file" className="btn btn-lg alignement btn-primary"> <strong> Choisir un fichier<br/>(csv, ods, xls ou xlsx)</strong>
                                        <input type="file" name="file" id="file" accept=".csv,.xls,.xlsx,.ods" className="btn_file" onChange={ (e) => this.handleFileChange(e.target.files) } />
                                    </label>
                                    <p>{this.state.file_name}</p>
                                </div>
                            </fieldset>

                            <fieldset>
                                <div>
                                    <label htmlFor="header" title="Numéro de la ligne qui contient les entêtes du fichier. Laissez vide si il n'y en a pas.">ligne d'en-tête : <input className="form-group" type="number" id="header" name="header" defaultValue={1}/></label>
                                    <br/>
                                    <label htmlFor="csv_sep" title="Le caractère séparateur dans le fichier csv" style={enableCsvFields ? null : {display: "none"}}>Séparateur csv : 
                                        <select type="text" id="csv_sep" name="csv_sep" disabled={!enableCsvFields}>
                                            <option value=";">,</option>
                                            <option value=",">;</option>
                                            <option value={'\t'}>tab</option>
                                        </select>

                                    </label>
                                </div>
                            </fieldset>
                        </div>

                        <div className="form-group">
                            <button type="submit" className="btn btn-lg btn-success"> Importer le fichier </button>
                        </div>
                    </form>
                </div>
            </Modal>
        );
    }
}

export default ImportFileModal;