/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
    route: '/app',
    rest_api_url: '/restapi/',
    rest_api_url_image:'/restapi/images/',
    websocket_service: 'wss://alerte70.dev-onlineformapro.com/ws/',
    server: 'https://alerte70.dev-onlineformapro.com/',
    REACT_APP_AUTHORIZE_ENDPOINT: 'https://alerte70.dev-onlineformapro.com/o/authorize/',
    REACT_APP_TOKEN_ENDPOINT: 'https://alerte70.dev-onlineformapro.com/o/token/',
    REACT_APP_CLIENT_ID: 'IMT5D6DB3YfqGCS8FcsWN1YtGEoiAV79Anip9eal',
    REACT_APP_CLIENT_SECRET: 'e9cl8qtXM9UcKgrgOEERrPOE34qezECoeAtGwNx7RhVYKfhkuXJDAUEBdy318XMRMoFYLma2TLSxxG0sE9VJcS1LzCB8h74ia768eRhixYkA3utKUp3RSwfXW8aFRIFc',
    REACT_APP_REDIRECT_URI: window.location.origin,
    REACT_APP_LOCATION: window.location
}