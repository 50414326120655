import React from "react";
import '../../Pages/Dashboard/scss/alert.scss';
import { Cross } from "./Button";

const Modal = ({ handleClose, show, archive, img, children, title, size='lg' }) => {
  const ShowHideClassName = show ? 'modal display-block' : 'modal display-none';
  return (
    <div className={ShowHideClassName}>
      <div className={`modal-dialog modal-${size}`} role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title"> {title} </h5>
            <Cross href="#" handleClick={() => handleClose()} data-dismiss="modal" aria-label="Close">
              <em className="icon ni ni-cross"></em>
            </Cross>
          </div>
          <div className="modal-body">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;