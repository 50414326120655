import React, { Component } from "react";
import { getEllapsedTime } from '../../utilitary';
import 'react-custom-scroll/dist/customScroll.css';

import './scss/alert.scss';
import config from '../../../config';

class AlertCard extends Component {
    constructor(props) {
        super(props);
        this.wrapper = React.createRef();
    }

    render() {
        const { id, active, title, has_image, communities, creation_date, plaintext_description } = this.props;
        const ellapsedTime = getEllapsedTime(creation_date);
        let img;

        if (communities.length === 1) {
            const { image_url } = communities[0];
            img = <img src={`${config.server.replace(/\/$/g, '')}${image_url}`} className="community-img" alt="img_commu" />;
        }
        else {
            img = <div className="community-img">{communities.length}</div>;
        }

        return (

            <div ref={this.wrapper} className={"card card-bordered alert-card" + (active ? " active " : " ")} data-id={id} onClick={this.props.onClick}>
                <div className="card-inner">
                    <div className="project">
                        <div className="project-head">
                            <div className="project-title" >
                                <div className="user-avatar">
                                    <span> { img } </span>
                                </div>
                                <div className="project-info">
                                    <h6 className="titles"> {`à :  ${communities.length === 1 ? communities[0].name : communities.length + ' Communautés'}`} </h6>
                                    <span className="sub-text tittle"> { title } </span>
                                </div>
                            </div>
                            <div className="project-meta">
                                <span className="badge badge-dim badge-warning">
                                    <span> { ellapsedTime } </span>
                                </span>
                            </div>
                        </div>
                        <div className="project-details">
                            <div className="user-card">
                                <div className="user-avatar test">
                                    { has_image ? <em className="icon-attach"></em> : ' ' }
                                </div>
                                <div className="user-info">
                                    <span className="lead-text fff "> 
                                        { plaintext_description }
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AlertCard;