import React, { Component } from 'react';
import Modal from "../Generics/Modal";
import { sendRegistrationEmailsForCommunity } from '../../webServices'
import ScaleLoader from "react-spinners/ScaleLoader";
import swal from 'sweetalert';
import dateFormat from "dateformat";

class SendCommunityEmailReminderModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            show: false,
            community: null,
            nb_recipients: 0,
            processing: false
        };

        this.callback = null;
        this.show = this.show.bind(this);
        this.hide = this.hide.bind(this);
        this.validateForm = this.validateForm.bind(this);
    }

    show(community = null, callback=null) {
        this.callback = callback;
        this.setState({
            show: true,
            community: community,
            nb_recipients: community.nb_enabled_members - community.nb_active_members
        });
        console.log(community);
    }

    hide() {
        if (this.state.processing) {
            return;
        }

        this.setState({ show: false, community: null, nb_recipients: 0 });
    }

    validateForm(e) {
        e.preventDefault();
        this.setState({processing: true});
        
        //TODO: to uncommt !
        sendRegistrationEmailsForCommunity(this.state.community.pk)
        .then(res => {
            //hide modal after Posting Alert
            this.hide();
            e.target.reset();
            this.setState({processing: false});

            this.state.community.last_email_reminder_date = Math.floor(Date.now() / 1000).toString();

            swal({
                icon: "success",
                title: "Emails envoyés avec succès",
                timer: 2500
            });
            if (typeof this.callback === "function") {
                this.callback(res.item);   
            }
        })
        .catch(error => {
            //TODO: display error message
            this.setState({processing: false});
        });
    }

    render() {
        var description = this.state.processing ? 
            <><div>Envoie en cours de {this.state.nb_recipients} email{this.state.nb_recipients > 1 ? 's' : ''}...<br/><br/>Merci de ne pas fermer la fenêtre du navigateur, cela peut prendre quelques minutes.</div></> :
            <><div> {this.state.nb_recipients} membres n'ont pas encore installé l'application et recevrons de nouveau le mail d'inscription.<br/><br/>Êtes vous sûr de vouloir continuer ?</div></>

        var last_reminder_label = "Jamais";
        var last_reminder_title = "Jamais";
        if (this.state.community && this.state.community.last_email_reminder_date) { 
            let time_since_last_reminder = Math.floor(Date.now() / 1000) - this.state.community.last_email_reminder_date;
            last_reminder_title =  dateFormat(new Date(this.state.community.last_email_reminder_date * 1000), '"Dernière relance le" dd/mm/yyyy "à" HH:MM');

            let today_ts = Math.floor(Date.now() / 1000);
            today_ts = today_ts - (today_ts % 86400);
            let h_delta = dateFormat(new Date(today_ts * 1000), 'h');
            today_ts -= h_delta * 3600;

            if (time_since_last_reminder < 60) {
                last_reminder_label = "Il y a moins d'une minute"
            } else if (time_since_last_reminder < 120) {
                last_reminder_label = "Il y une minute"
            } else if (time_since_last_reminder < 600) {
                last_reminder_label = "Il y a quelques minutes"
            }else if (time_since_last_reminder < 3600) {
                last_reminder_label = "Il y a moins d'une heure"
            } else if (this.state.community.last_email_reminder_date > today_ts) {
                last_reminder_label = "Aujourd'hui"
            } else if (this.state.community.last_email_reminder_date > today_ts - 86400*7) {
                last_reminder_label = "Il y a moins d'une semaine"
            } else {
                last_reminder_label =  dateFormat(new Date(this.state.community.last_email_reminder_date * 1000), '"Le" dd/mm/yyyy');
            }
        }

        return (
            <Modal show={this.state.show} handleClose={this.hide} title="Relance email d'inscription">
                <div className="card-inner">
                    <h6>Communauté: {this.state.community ? this.state.community.name : ""}</h6>
                    <br/>
                    <div title={last_reminder_title}>
                    Dernière relance : <b>{last_reminder_label}</b>
                    </div>
                    <br/>
                    {description}
                    <br/>
                    <form onSubmit={this.validateForm} id="add-community-form" className='popupForm'>
                        <div className="form-group">
                            <button type="submit" className="btn btn-lg btn-success bg-gradient" disabled={this.state.processing || this.state.nb_recipients === 0}>{this.state.processing ? <ScaleLoader color={"#ffffff"} height={20} width={10} /> : this.state.nb_recipients === 0 ? "Aucun membre à relancer" : `Envoyer ${this.state.nb_recipients} email${this.state.nb_recipients > 1 ? 's' : ''}`}</button>
                        </div>

                    </form>
                </div>
            </Modal>
        );
    }
}

export default SendCommunityEmailReminderModal;