import React, { Component } from "react";
import CustomScroll from "react-custom-scroll";
import { Link } from "react-router-dom";
import DefaultLogo from '../../../../img/default_alert_image.png';
import Modal from "../../Generics/Modal";
import DetailRecipients from "./DetailRecipientsWidget";
import { getAlertImage } from '../../../webServices';

/** Markdown */
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import gfm from 'remark-gfm'
/** Markdown */

class DetailWidget extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
        };

        this.wrapper = React.createRef();
        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
    }

    //Show Modal
    showModal() {
        this.setState({ show: true });
    };

    //hide Modal
    hideModal() {
        this.setState({ show: false });
    };

    async componentDidMount() {
        if (this.props.alert.has_image) {
            const image = await getAlertImage(this.props.alert.id);
            this.setState({
                image: image
            });
        }
    }

    async componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.alert.id !== this.props.alert.id) {
            if (this.props.alert.has_image) {
                const image = await getAlertImage(this.props.alert.id);
                this.setState({
                    image: image
                });
            }
        }
    }

    render() {
        return (
            <div className="card card-bordered bord card-full eval" ref={this.wrapper}>

                <img className="card-img-top image_alerte" src={this.props.alert.has_image ? this.state.image : DefaultLogo} srcSet={this.props.alert.has_image ? this.state.image : DefaultLogo} alt="." />
                <div className="card-inner">
                    <h5 className="card-title perso">{this.props.alert.title}</h5>
                    <p className="card-text card-details persos"> {this.props.alert.plaintext_description} </p>
                </div>
                <Modal show={this.state.show} img={this.props.alert.has_image ? this.state.image : null} handleClose={this.hideModal} title={this.props.alert.title}>

                    <div className="descModal h-auto d-inline-block">
                        <CustomScroll allowOuterScroll={true} heightRelativeToParent={'calc(100% - 2.6rem)'} >

                            <ReactMarkdown children={this.props.alert.description} remarkPlugins={[gfm, remarkGfm]} />

                        </CustomScroll>
                    </div>

                </Modal>

                <Link to="#" type="button" className="readmore perso_readmore" onClick={this.showModal}> Lire plus </Link>

                <hr className="hr" />

                <div className="card-inner" >
                    <h5 className="card-title ctrl_title"> { this.props.alert.nb_people_affected <= 1 ? "Personne atteinte" : "Personnes atteintes" } </h5>
                    <div className="card-body body_card">
                        <h2 className="atteinte"> { this.props.alert.nb_people_affected } </h2>
                        <span>
                            <DetailRecipients
                                alert={this.props.alert}
                                communities={this.props.alert.communities}
                            />
                        </span>
                    </div>
                </div>

            </div>
        );
    }
}

export default DetailWidget;