import React, { Component } from 'react';
import Modal from "./Modal.jsx";
import { closeAlert } from "../../webServices";
import swal from 'sweetalert';

class ClosedMessage extends Component {
    constructor(props) {
        super(props);
        this.handleClose = this.handleClose.bind(this);
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.state = {
            processing: false,
        };
    }

    handleClose() {
        this.setState({ processing: false });
        if (typeof this.props.handleClose === "function") {
            this.props.handleClose();
        }
    }
    handleFormSubmit(e) {
        this.setState({ processing: true });
        e.preventDefault();
        closeAlert(this.props.alert.id, e.target.message.value)
        .then(res => {
            e.target.reset();
            swal({
                icon: "success",
                title: "Alerte Archivée",
                timer: 1500
            });

            this.handleClose();

            //Reload After Modify 
            setTimeout(() => {
                window.location.reload();
            }, 1500);
        })
        .catch(err => {
            swal({
                icon: "error",
                title: "Une erreur est survenue",
                text: "Impossible d'archiver l'alerte."
            });
            this.setState({ processing: false });
            //TODO
        });
    }

    render() {
        return (
            <Modal show={this.props.show} handleClose={this.handleClose} title={this.props.title}>
                <div className="card-inner">
                    <form onSubmit={this.handleFormSubmit} id="add_alert">
                        <div className="form-group">
                            <textarea id="message" name="message" className="form-control" rows="5" placeholder="Message de cloture de l'alerte" disabled={this.state.processing}></textarea>
                        </div>

                        <div className="form-group">
                            <button type="submit" className="btn btn-success" id="Submit_btn" disabled={this.state.processing}> Archiver </button>
                        </div>
                    </form>
                </div>
            </Modal>
        );
    }
}

export default ClosedMessage;