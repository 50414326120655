import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../../img/logo-alerte-70-header.png';
import Loading from '../../Loading';

import { Doughnut } from "react-chartjs-2";
import Mainheader from '../../Header';

import { getAlerts, getGlobalresponsesStats } from "../../webServices";
import ListAlertHistory from './ListAlertHistory';

class History extends Component {
    constructor(props) {
        super(props);
        this.state = {
            alerts: [],
            item: [],
            error: null,
            errorInfo: null,
            statData: [],
            loading: false
        };
    }

    async componentDidMount() {
        const allAlertes = await getAlerts();
        const data = await getGlobalresponsesStats();

        this.setState({
            alerts: allAlertes,
            item: data,
            loading: true,
            ...formatData(data)
        });
    }

    async componentDidUpdate(prevProps) {

    }

    static getDerivedStateFromError(error) {
        return {
            hasError: true
        }
    }

    componentDidCatch(error, errorInfo) {
        this.setState({
            error: error,
            errorInfo: errorInfo
        });
    }

    render() {
        return (
            <div>
                <div className="nk-app-root">
                    <div className="nk-main">
                        <div className="nk-sidebar nk-sidebar-fixed is-light " data-content="sidebarMenu">
                            <div className="nk-sidebar-element nk-sidebar-head">
                                <div className="nk-menu-trigger">
                                    <Link to={"/"} className="nk-nav-toggle nk-quick-nav-icon d-xl-none" data-target="sidebarMenu"><em className="icon ni ni-arrow-left" /></Link>
                                    <Link to={"/"} className="nk-nav-compact nk-quick-nav-icon d-none d-xl-inline-flex" data-target="sidebarMenu"><em className="icon ni ni-menu" /></Link>
                                </div>

                                <div className="nk-sidebar-brand">
                                    <Link to={'/'} className="logo-link nk-sidebar-logo">
                                        <img className="logo-light logo-img" src={logo} srcSet={logo} width={150} alt="logo" />
                                        <img className="logo-dark logo-img" src={logo} srcSet={logo} width={150} alt="logo-dark" />
                                    </Link>
                                </div>

                            </div>

                            {/* <!-- .nk-sidebar-element --> */}

                            <div className="nk-sidebar-element nk-sidebar-body">

                                <div className="nk-sidebar-content">

                                    <div className="nk-sidebar-menu" data-simplebar>

                                        <div className="nk-menu">

                                            <div id="alerte_emises" className={'alert-group'}>
                                                <h5 className="alertes_emises"> { this.state.alerts['nb-total-results'] <= 1 ? "Alerte émise" : "Alertes émises" } </h5>
                                                <p id="truc" className="display-5"> { this.state.alerts['nb-total-results'] } </p>
                                            </div>

                                            <br />

                                            <div id="typeofresponses" className={'alert-group'}>

                                                <div className="card card-bordered type_response truc_border_opening eval">
                                                    
                                                    <div className="card-inner">

                                                        <br />

                                                        <div className="card-title-group">

                                                            <div className="card-title text-center">
                                                                <h6 className="title response_type"> Types de réponses </h6>
                                                            </div>

                                                        </div>

                                                        <br />
                                                        
                                                        <div className="form-group by_community">
                                                            <div className="form-control-wrap list_commu">


                                                            </div>
                                                        </div>
                                                        
                                                        <Doughnut
                                                            data={{
                                                                datasets: [
                                                                    {
                                                                        label: "response-stat",
                                                                        data: this.state.statData,
                                                                        backgroundColor: ['#F1F1F5', '#9E9E9E', '#FBC02D', '#1B5E20', '#E65100', '#D32F2F'],
                                                                        borderWidth: 2,
                                                                    }
                                                                ]
                                                            }}
                                                            options={{
                                                                radius: 90,
                                                                layout: 20,
                                                                cutout: 100,
                                                                radiusBackground: {
                                                                    color: '#F1F1F5'
                                                                }
                                                            }}
                                                        />

                                                        { this.state.loading ? null : <Loading /> }

                                                        <p className="nb_responses_global_stat">
                                                            <span className="type_rep" id="type_rep"> { this.state.item.nb_responses } </span> <br />
                                                            <span className="res"> { this.state.item.nb_responses <= 1 ? "réponse" : "réponses" } </span>
                                                        </p>

                                                        <ul className="Response_rate">

                                                            <li>
                                                                <small className="dotColorThanks" ></small>
                                                                <span className={"rated"} id="responses-1"> { this.state.statData[1] }% </span> Merci
                                                            </li>

                                                            <li>
                                                                <small className="dotColorNodepart"></small>
                                                                <span className={"rated"} id="responses-2"> { this.state.statData[2] }% </span> Pas dans le département
                                                            </li>

                                                            <li>
                                                                <small className="dotColorNozone"></small>
                                                                <span className={"rated"} id="responses-3"> { this.state.statData[3] }% </span> Pas dans la zone
                                                            </li>

                                                            <li>
                                                                <small className="dotColorVigil"></small>
                                                                <span className={"rated"} id="responses-4"> { this.state.statData[4] }% </span> Je reste vigilant(e)
                                                            </li>

                                                            <li>
                                                                <small className="dotColorIntel"></small>
                                                                <span className={"rated"} id="responses-5"> { this.state.statData[5] }% </span> J'ai des infos
                                                            </li>

                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {/* <!-- .nk-menu --> */}

                                    </div>

                                    {/* <!-- .nk-sidebar-menu --> */}
                                </div>

                                {/* <!-- .nk-sidebar-content --> */}
                            </div>

                            {/* <!-- .nk-sidebar-element --> */}
                        </div>

                        <div className="nk-wrap">

                            <Mainheader />

                            <div className="nk-content h-100">
                                <div className="container-fluid">
                                    <div className="nk-content-inner">
                                        <div className="nk-content-body">
                                            <div className="nk-block">
                                                <div className="row g-gs">
                                                    
                                                    <div className="col-md-3 marginn"> 
                                                        <h4 className="history"> Historique des alertes </h4>
                                                    </div>
                                                    <div className="col-md-6 marginn">

                                                    </div>
                                                    <ListAlertHistory />
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default History;

function formatData(data) {
    const statData = [0.001];

    for (var i = 1; i < 6; ++i) {
        statData.push(Math.round(data.responses[i] / data.nb_responses * 100) || 0);
    }

    return {
        nb_responses: data.nb_responses,
        statData: statData
    }
}