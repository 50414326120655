import React, { Component } from 'react'
import config from '../../../config';

class AlertHistoryRow extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    render() {
        const { index, title, communities, nb_people_affected, creation_date, cloture_date } = this.props;

        /** Durée des anonces */
        const cloture = new Date(cloture_date * 1000).getTime();
        const creation = new Date(creation_date * 1000).getTime();
        var Durée = cloture - creation;

        const Weeksdifference = Math.floor(Durée / 1000 / 60 / 60 / 24 / 7);
        Durée -= Weeksdifference * 1000 * 60 * 60 * 24 * 7;
        const Daysdifference = Math.floor(Durée / 1000 / 60 / 60 / 24);
        Durée -= Daysdifference * 1000 * 60 * 60 * 24;
        const Hourdifference = Math.floor(Durée / 1000 / 60 / 60);
        Durée -= Hourdifference * 1000 * 60 * 60;
        const Minutedifference = Math.floor(Durée / 1000 / 60);
        Durée -= Minutedifference * 1000 * 60;
        //const Seconddifference = Math.floor(Durée / 1000);
        /** Durée des annonces */

        const Jours   = Daysdifference   > 0 ? Daysdifference   + ( Daysdifference   === 1 ? " jr "  : " jrs " ) : " ";
        const Joursd  = Daysdifference;
        const Heures  = Hourdifference   > 0 ? Hourdifference   + ( Hourdifference   === 1 ? " h "   : " h " ) : " ";
        const Minutes = Minutedifference > 0 ? Minutedifference + ( Minutedifference === 1 ? " mn "  : " mns " ) : " ";
        //const Minitesd = Minutedifference;
       // const Secondes = Seconddifference > 0 ? Seconddifference + ( Seconddifference === 1 ? " s "   : " s " ) : " ";

        let img;
        if (communities.length === 1) {
            const { image_url } = communities[0];
            img = <img src={`${config.server.replace(/\/$/g, '')}${image_url}`} className="community-img" alt="img_commu" />;
        }

        else {
            img = <div className="community-img">{communities.length}</div>;
        }

        return (
            <tr className="table_box" key={index}>
                <td className="project-title">
                    <div className="user-avatar">
                        <span> {img} </span>
                    </div>
                    <span className="project-info" key={index}> { title } </span>
                </td>

                <td className="text-center"> 
                   
                    <div className="commu_list">

                        {
                            communities.map((coo, indx) => <span key={indx}> {coo.name}  </span>)
                        }

                    </div>

                </td>

                <td className="text-center">
                    {
                        nb_people_affected
                    }
                </td>

                <td className="text-center" >
                    {
                        new Date(creation_date * 1000).toLocaleDateString('fr-FR', {
                            year: 'numeric',
                            month: '2-digit',
                            day: '2-digit'
                        })
                    }
                </td>

                <td className="text-center">
                    {
                        Joursd >= 1 ? Jours.concat(Heures) : Heures.concat(Minutes)
                    }
                </td>
            </tr>
        );
    }
}

export default AlertHistoryRow;