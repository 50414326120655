import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../../img/logo-alerte-70-header.png';
import { ImPlus } from 'react-icons/im';

import Mainheader from '../../Header';
import ListCategories from './ListCategories';
import AddCategoryModal from './AddCategoryModal';

class Categories extends Component {
    constructor(props) {
        super(props);

        this.modalRef = React.createRef();

        this.addCategory = this.addCategory.bind(this);
        this.editCategory = this.editCategory.bind(this);
        this.archiveCategory = this.archiveCategory.bind(this);
        this.getListCategoriesPage = this.getListCategoriesPage.bind(this);
        this.goToCategoryList = this.goToCategoryList.bind(this);

        this.state = { current_page: this.getListCategoriesPage() };
    }

    getListCategoriesPage() {
        return <ListCategories editCategory={this.editCategory} archiveCategory={this.archiveCategory} />
    }

    goToCategoryList() {
        this.setState({
            current_page: this.getListCommunitiesPage()
        });
    }

    addCategory() {
        this.modalRef.current.show();
    }

    editCategory(category, callback) {
        this.modalRef.current.show(category, callback);
    }

    archiveCategory(category) {
        //TODO
    }

    static getDerivedStateFromError(error) {
        return {
            hasError: true
        }
    }

    render() {
        return (
            <div>
                <div className="nk-app-root">
                    <div className="nk-main">
                        <div className="nk-sidebar nk-sidebar-fixed is-light " data-content="sidebarMenu">
                            <div className="nk-sidebar-element nk-sidebar-head">
                                <div className="nk-menu-trigger">
                                    <Link to="/" className="nk-nav-toggle nk-quick-nav-icon d-xl-none" data-target="sidebarMenu"><em className="icon ni ni-arrow-left" /></Link>
                                    <Link to="/" className="nk-nav-compact nk-quick-nav-icon d-none d-xl-inline-flex" data-target="sidebarMenu"><em className="icon ni ni-menu" /></Link>
                                </div>
                                <div className="nk-sidebar-brand">
                                    <Link to="/" className="logo-link nk-sidebar-logo">
                                        <img className="logo-light logo-img" src={logo} srcSet={logo} width={150} alt="logo" />
                                        <img className="logo-dark logo-img" src={logo} srcSet={logo} width={150} alt="logo-dark" />
                                    </Link>
                                </div>
                            </div>
                            {/* <!-- .nk-sidebar-element --> */}
                            <div className="nk-sidebar-element nk-sidebar-body">
                                <div className="nk-sidebar-content">
                                    <div className="nk-sidebar-menu" data-simplebar>
                                        <div className="nk-menu">
                                            <div className='btn-menu'>
                                                <div className="btn btn-primary import_file" type="button" onClick={() => { this.addCategory() }}> <ImPlus /> &nbsp; Ajouter une catégorie</div>
                                            </div>
                                        </div>
                                        {/* <!-- .nk-menu --> */}
                                    </div>
                                    {/* <!-- .nk-sidebar-menu --> */}
                                </div>
                                {/* <!-- .nk-sidebar-content --> */}
                            </div>
                            {/* <!-- .nk-sidebar-element --> */}
                        </div>
                        
                        <div className="nk-wrap">

                            <Mainheader />

                            <div className="nk-content h-100">
                                <div className="container-fluid">
                                    <div className="nk-content-inner">
                                        <div className="nk-content-body">
                                            <div className="nk-block">

                                                <div className="row g-gs">

                                                    <div className="col-md-3 marginn">
                                                        <h4 className="history"> Catégories </h4>
                                                    </div>

                                                    {this.state.current_page}

                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
                <AddCategoryModal ref={this.modalRef} />
            </div>
        );
    }
}

export default Categories;