import React from "react";
import NoAlerte from '../../img/no-alarm.png';

function NoAlert() {
    return (
        <div className="nk-body bg-white npc-general pg-error bg-no-alert">
            <div className="nk-app-root">
                {/* <!-- main @s -->*/}
                <div className="nk-main">
                    {/* <!-- wrap @s -->*/}
                    <div className="nk-wrap nk-wrap-nosidebar no-width">
                        {/* <!-- content @s -->*/}
                        <div className="nk-content no-width content-error">
                            <div className="nk-block nk-block-middle wide-xs mx-auto">
                                <div className="nk-block-content nk-error-ld text-center Element-center ">
                                    <img className="position-relative top-50 start-100 translate-middle" src={NoAlerte} alt="Pas d'alerte disponible_" />
                                    <p className="nk-error-text"> Pas d'alerte active en cours pour le moment </p>
                                </div>
                            </div>{/** <!-- .nk-block -->*/}
                        </div>
                        {/* <!-- wrap @e -->*/}
                    </div>
                    {/*<!-- content @e -->*/}
                </div>
                {/*<!-- main @e -->*/}
            </div>
        </div>
    );
}

export default NoAlert;