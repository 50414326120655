import React, { Component } from "react";

class Loading extends Component {
    render() {
        return (
            <div className="col loaded">
                <div className="spinner-grow text-primary" role="status">
                    <span className="sr-only"> Loading... </span>
                </div>

                <div className="spinner-grow text-secondary" role="status">
                    <span className="sr-only"> Loading... </span>
                </div>
                
                <div className="spinner-grow text-success" role="status">
                    <span className="sr-only"> Loading... </span>
                </div>

                <div className="spinner-grow text-info" role="status">
                    <span className="sr-only"> Loading... </span>
                </div>

                <div className="spinner-grow text-light" role="status">
                    <span className="sr-only"> Loading... </span>
                </div>
            </div>
        );
    }
}

export default Loading;