import React from "react";
import 'react-custom-scroll/dist/customScroll.css';
import AlertHistoryRow from './AlertHistoryRow';
import { getInactiveAlerts } from '../../webServices';
import { GenericDataList, Field } from '../Generics/GenericDataList';

class ListAlertHistory extends GenericDataList {
  constructor(props) {
    super(props, 7);
    this.loadData = getInactiveAlerts;
    this.columns = [/**new Field("", ""),*/ new Field("title", "Titre de l'alerte", true, true), new Field("community__name", "Communautés", false, false), new Field("", "Personnes Touchées"), new Field("creation_date", "Création", true), new Field("", "Durée"),/** new Field("", "Action")*/]
  }

  get_data_row(data, index) {
    const { id, title, creation_date, nb_people_affected, has_image, communities, cloture_date } = data;

    return (
      <AlertHistoryRow
        creation_date={creation_date}
        cloture_date={cloture_date}
        key={index}
        id={id}
        title={title}
        nb_people_affected={nb_people_affected}
        has_image={has_image}
        communities={communities}
      />
    );
  }
}

export default ListAlertHistory