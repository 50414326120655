import React from "react";
/** import Erreur404 from '../../img/404.gif';*/
import Error404 from '../../img/error-404.svg';
import { Link } from "react-router-dom";

function Erreur() {
    return (
        <div className="nk-body bg-white npc-general pg-error">
            <div className="nk-app-root">
                {/* <!-- main @s -->*/}
                <div className="nk-main">
                    {/* <!-- wrap @s -->*/}
                    <div className="nk-wrap nk-wrap-nosidebar">
                        {/* <!-- content @s -->*/}
                        <div className="nk-content ">
                            <div className="nk-block nk-block-middle wide-xs mx-auto">
                                <div className="nk-block-content nk-error-ld text-center">
                                    <img src={Error404} alt='erreur 404' />
                                    <p className="nk-error-text"> Il semble que vous essayez d'accéder à une page qui n' existe pas. </p>
                                    <Link to={"/"} className="btn btn-lg btn-primary mt-2"> Retour </Link>
                                </div>
                            </div>{/** <!-- .nk-block -->*/}
                        </div>
                        {/* <!-- wrap @e -->*/}
                    </div>
                    {/*<!-- content @e -->*/}
                </div>
                {/*<!-- main @e -->*/}
            </div>
        </div>
    );
}

export default Erreur;