import React, { Component } from "react";
import CustomScroll from "react-custom-scroll";
import 'react-custom-scroll/dist/customScroll.css';
import Loading from "../../../Loading";
import { getAlertRecipients } from '../../../webServices'

//import config from '../../../../config';
class DetailRecipients extends Component {
  constructor(props) {
    super(props);

    this.state = {
      is_loaded: false,
      recipients: [],
    };
  }

  componentDidMount(error) {
    getAlertRecipients(this.props.alert.id).then(
      (recipients) => {
        this.setState({
          is_loaded: true,
          recipients: recipients.people_affected,
        });
      }
    );
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.alert.id !== this.props.alert.id) {
      getAlertRecipients(this.props.alert.id).then(
        (recipients) => {
          this.setState({
            is_loaded: true,
            recipients: recipients.people_affected,
          });
        }
      );
    }
  }

  render() {
    if (this.state.is_loaded) {
      return (
        <div className="details_recipients">
          <CustomScroll heightRelativeToParent={"100%"}>
            {
              this.props.communities.map((data, key) => (
                <div key={key} className="item_Commu">
                  <div className={`${data.slug} dotColor`} style={{ 'backgroundColor': data.color }} />
                  <span className="number_commu_type"> { this.state.recipients[data.slug] ? this.state.recipients[data.slug] : 0 } </span>
                  <span className="commu_type"> { data.name } </span>
                </div>
              ))
            }
          </CustomScroll>
        </div>
      );
    }
    else {
      return (
        <div className="details_recipients"> <Loading /> </div>
      );
    }
  }
}

export default DetailRecipients