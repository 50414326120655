import React from 'react';

const Button = ({children, handleClick, icon, icon_after, className="btn btn-block btn-info"}) => {
  const icon2 = <i className={`icon icon-${icon}`} />;

  return (
    <div className={className} onClick={handleClick}>
      <span className="icons_box"> {icon && !icon_after ? icon2 : null} {children} </span>
        {icon && icon_after ? icon2 : null}
    </div>
  );
};

export default Button;

export const Cross = (props) => {
  return <Button children={props.children} handleClick={props.handleClick} icon={props.icon} icon_after={props.icon_after} className="close curs"/>;
}