import React, { Component } from "react";
import { Link } from 'react-router-dom';
import 'react-custom-scroll/dist/customScroll.css';
import { getCategories } from '../../webServices'
import { GenericDataList, Field } from '../Generics/GenericDataList'

class CategoriesRow extends Component {
  constructor(props) {
    super(props);
    this.state = { category: this.props.category };
    this.onEdited = this.onEdited.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.category.pk !== prevProps.category.pk) {
      this.setState({ category: this.props.category });
    }
  }

  onEdited(category) {
    this.setState({ category: category });
    if (typeof this.props.onDataChanged === "function") {
      this.props.onDataChanged();
    }
  }

  render() {
    
    const { category } = this.state;

    let action_btn = <>
      <Link className="btn btn-trigger btn-icon" to="#" onClick={() => { this.props.editCategory(category, this.onEdited); }} title="Modifier le libelle"> <em className="icon ni ni-edit-alt edito"></em></Link>
      {/*<Link to="#" className="cancel-link" onClick={() => { archiveCategory(category); }} title="Archiver la catégorie"> Archiver </Link>*/}
    </>;

    return (
      <tr>
        <td className="text-left"> {category.name} </td>
        <td className="text-center"> {action_btn} </td>
      </tr>
    );

  }

}


class ListCategories extends GenericDataList {
  constructor(props) {
    super(props, 10);
    this.loadData = getCategories;
    this.columns = [new Field("name", "Catégorie", true, true), new Field("", "Action", false, false, "text-center")];
    this.onDataChanged = this.onDataChanged.bind(this);
  }

  onDataChanged() {
    this.clear_cache();
  }

  get_data_row(data, index) {
    //const { pk, name } = data;
    return (
      <CategoriesRow
        key={index}
        category={data}
        editCategory={this.props.editCategory}
        archiveCategory={this.props.archiveCategory}
        onDataChanged={this.onDataChanged}
      />
    );
  }
}

export default ListCategories