import React, { Component } from "react";
import { Doughnut } from "react-chartjs-2";
import config from '../../../../config';
import Loading from "../../../Loading";
import { getAlertResponses, getResponsebycommunity, getAccesToken } from '../../../webServices';

class ResponseWidget extends Component {
  constructor(props) {
    super(props);
    this.state = {
      opening_by_community: [],
      merci: null,
      not_depart: null,
      not_zone: null,
      vigilant: null,
      intel: null,
      no_commu: -1,
      chartData: []
    };

    this.ws = null;
    this.wsConnect = this.wsConnect.bind(this);
  }

  wsConnect() {
    this.wsClose();
    this.ws = new WebSocket(`${config.websocket_service}alert/${this.props.alert.id}/responses?subscribe-broadcast&access_token=${getAccesToken()}`);

    this.ws.onopen = function () {
    };

    this.ws.onmessage = (e) => {
      let res = JSON.parse(e.data);

      if (res.widget === "responses") {
        this.setState({
          ...formatData(res.data),
        });
      }
    };

    let context = this;
    this.ws.onerror = function (e) {
      console.error("ws error: ", e);
      context.wsClose();
    };

    //
    this.ws.onclose = function (e) {
    }
  }

  wsClose() {
    if (this.ws && this.ws.readyState === 1) {
      this.ws.close();
    }
  }

  async componentDidMount() {
    this.wsConnect();
    const data = await getAlertResponses(this.props.alert.id);
    //const communities = await getOpeningbyCommunity(this.props.alert.id);

    this.setState({
      y_esta: data.success,
      cargamento: true,
      no_commu: -1,
      ...formatData(data)
    });
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {

    if (prevProps.alert.id !== this.props.alert.id) {
      this.wsConnect();

      const data = await getAlertResponses(this.props.alert.id);
      //const communities = await getOpeningbyCommunity(this.props.alert.id);

      this.setState({
        y_esta: data.success,
        cargamento: true,
        no_commu: -1,
        ...formatData(data)
      });
    }

    if (prevState.no_commu !== this.state.no_commu) {
      const data = await getResponsebycommunity(this.props.alert.id, this.state.no_commu);
      this.setState({
        ...formatData(data)
      });
    }
  }

  componentWillUnmount() {
    this.wsClose();
  }

  render() {

    //const default_community_value = {value: null, label: 'tout'};
    //let community_opt = [default_community_value, ...this.props.alert.communities.map(community => ({value: community.pk, label: community.name}))];

    return (
      <div className="card card-bordered type_response truc_border_opening eval style_response">
        <div className="card-inner">

          <br />

          <div className="card-title-group">
            <div className="card-title text-center">
              <h6 className="title response_type"> Types de réponses </h6>
            </div>
          </div>
          
          <br />

          <div className="traffic-channel">

            <Doughnut id="colors"
              data={{
                datasets: [
                  {
                    label: "alerte70",
                    data: this.state.chartData,
                    backgroundColor: ['#F1F1F5', '#9E9E9E', '#FBC02D', '#1B5E20', '#E65100', '#D32F2F'],
                    borderWidth: 2,
                  }
                ]
              }}
              options={{
                radius: 90,
                //layout: 20,
                cutout: 150,
                radiusBackground: {
                  color: '#F1F1F5'
                }
              }} 
            />

            {this.state.cargamento ? null : <Loading />}

            <p className="nb_responses">
              <span className="type_rep" id="type_rep"> {this.state.nb_responses} </span>
              <br />
              <span className="res"> { this.state.nb_responses <= 1 ? "réponse" : "réponses" } </span>
            </p>

            <ul className="Response_rate">

              <li>
                <small className="dotColorThanks" ></small>
                <span className={"rated"} id="responses-1"> {this.state.chartData[1]}% </span> Merci
              </li>

              <li>
                <small className="dotColorNodepart"></small>
                <span className={"rated"} id="responses-2"> {this.state.chartData[2]}% </span> Pas dans le département
              </li>

              <li>
                <small className="dotColorNozone"></small>
                <span className={"rated"} id="responses-3"> {this.state.chartData[3]}% </span> Pas dans la zone
              </li>

              <li>
                <small className="dotColorVigil"></small>
                <span className={"rated"} id="responses-4"> {this.state.chartData[4]}% </span> Je reste vigilant(e)
              </li>

              <li>
                <small className="dotColorIntel"></small>
                <span className={"rated"} id="responses-5"> {this.state.chartData[5]}% </span> J'ai des infos
              </li>

            </ul>

          </div>
        </div>
      </div>
    );
  }
}

export default ResponseWidget;

// format données pour les types de reponses
function formatData(data) {
  const chartData = [
    0.00000000001
  ];
  for (var i = 1; i < 6; ++i) {
    chartData.push(Math.round(data.responses[i] / data.nb_responses * 100) || 0);
  }

  return {
    nb_responses: data.nb_responses,
    chartData: chartData
  }
}
