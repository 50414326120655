function secondsToDhms(seconds, day_with_hours) {
  seconds = Number(seconds);

  const d = Math.floor(seconds / (3600 * 24));
  const h = Math.floor((seconds % (3600 * 24)) / 3600);

  /** Afficher les minutes ecoulées  */
  const m = Math.floor((seconds % 3600) / 60);
  //const s = Math.floor(seconds % 60);

  if (day_with_hours === undefined) {
      day_with_hours = true;
  }

  const dDisplay = `${d > 0 ? d + (d === 1 ? " Jour " : " Jours ") : ""}`;
  const hDisplay = `${h > 0 ? h + " h " : ""}`;
  let mDisplay = "";

  if (d < 1) {
    /** Afficher les minutes ecoulées  */
    mDisplay = `${m > 0 ? m + " min": " "}`;
    // var sDisplay = `${s > 0 ? s + (s === 1 ? " second" : " seconds") : ""}`;
  }

  return `${seconds < 60
      ? !day_with_hours
        ? " < 1 min "
        : " 1 min"
        : dDisplay + (d === 0 || day_with_hours ? hDisplay + mDisplay : " ")
  }`;

}

export function getEllapsedTime(creation_date) {
  const alert_date = new Date(creation_date * 1000);
  const now = new Date();
  const time_difference = (now.getTime() - alert_date.getTime()) / 1000;

  if (now.toDateString() === alert_date.toDateString()) {
    // même jour
    return `Il y a ${secondsToDhms(time_difference, false)}`;
  }

  const yesterday = new Date();
  yesterday.setDate(now.getDate() - 1);
  yesterday.setHours(0);
  yesterday.setMinutes(0);

  yesterday.setSeconds(0);

  if (yesterday.toDateString() === alert_date.toDateString()) {
    // hier
    return "Hier";
  }
  
  const d = Math.floor(time_difference / (3600 * 24));
  return `Il y a ${d > 0 ? d + (d === 1 ? " jour " : " jours ") : " "}`;


  /** 
   * const w = Math.floor(time_difference / (3600 * 24 * 7));
      return `Il y'a ${w > 0 ? w  + (w === 1 ? " sem " : " sems " ): " "}`;
   * 
  */

}

export function getDuration(creation_date, cloture_date) {

  const alert_date = new Date(creation_date * 1000);
  const closure_date = new Date(cloture_date * 1000);
  const now = new Date();
  const time_difference = (cloture_date.getTime() - alert_date.getTime()) / 1000;

  if (closure_date.toDateString() === alert_date.toDateString()) {
    // même jour
    return `Il y a ${secondsToDhms(time_difference, false)}`;
  }

  const yesterday = new Date();
  yesterday.setDate(now.getDate() - 1);
  yesterday.setHours(0);
  yesterday.setMinutes(0);

  yesterday.setSeconds(0);

  if (yesterday.toDateString() === alert_date.toDateString()) {
    // hier
    return "Hier";
  }

  const d = Math.floor(time_difference / (3600 * 24));

  return `Il y a ${d > 0 ? d + (d === 1 ? " jour " : " jours ") : ""}`;

}

export default secondsToDhms;
