import React, { Component } from 'react';
import Modal from "../Generics/Modal.jsx";
import Async from 'react-select/async';
import swal from 'sweetalert';
import { addmembers, editmembers, getCitiesSelectOptionsAsync } from '../../webServices';

class MemberEditModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: "",
            lastname: "",
            lastname_has_changed: false,
            firstname: "",
            firstname_has_changed: false,
            email: "",
            email_has_changed: false,
            commupk: "",
            city: null,
            processing: false,
            validInput: false,
            emailIsValid: false,
        };


        this.show = this.show.bind(this);
        this.hide = this.hide.bind(this);
        this.initState = this.initState.bind(this);
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.handleChangeLastnameValue = this.handleChangeLastnameValue.bind(this);
        this.handleChangeFirstnameValue = this.handleChangeFirstnameValue.bind(this);
        this.emailIsValid = this.emailIsValid.bind(this);
        this.handleChangeMailValue = this.handleChangeMailValue.bind(this);
        this.checkValidInput = this.checkValidInput.bind(this);
        this.handleCityChanged = this.handleCityChanged.bind(this);

    }

    async initState(member, community_pk, community_name) {
        this.setState({
            show: true,
            pk: member ? member.pk : "",
            title: member ? "Modifier " + member.firstname + " " + member.lastname : "Nouveau membre dans la communauté " + community_name,
            lastname: member ? member.lastname : "",
            firstname: member ? member.firstname : "",
            email: member ? member.email : "",
            lastname_has_changed: false,
            firstname_has_changed: false,
            email_has_changed: false,
            emailIsValid: member ? member.email : false,
            city: member ? { value: member.city.pk, label: `${member.city.name} (${member.city.zip})` } : null,
            commun_name: community_name,
            commupk: community_pk,
            processing: false
        });
    }

    show(member = null, community_pk = null, community_name = null, callback = null) {
        this.initState(member, community_pk, community_name);
        this.callback = callback;
    }

    hide() {
        this.setState({ show: false, member: null, title: "", validInput: false, processing: false });
    }

    handleChangeLastnameValue(e) {
        this.setState({
            lastname: e.target.value,
            lastname_has_changed: true,
        });
    }

    handleChangeFirstnameValue(e) {
        this.setState({
            firstname: e.target.value,
            firstname_has_changed: true,
        });
    }

    emailIsValid(email) {
        const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return email && regex.test(email) !== false;
    }

    handleChangeMailValue(e) {
        const value = e.target.value;
        this.setState({
            email: value,
            emailIsValid: this.emailIsValid(value),
            email_has_changed: true
        });
    }

    checkValidInput() {
        return this.state.firstname.trim().length > 0 && this.state.lastname.trim().length > 0 && this.state.emailIsValid && this.state.city
    }

    handleCityChanged(city) {
        this.setState({
            city: city,
            city_has_changed: city ? true : false
        });
    }

    handleFormSubmit(e) {
        this.setState({ processing: true });
        e.preventDefault();
        var bodyFormData = new FormData();

        if (this.state.commun_name) {
            bodyFormData.append('lastname', this.state.lastname);
            bodyFormData.append('firstname', this.state.firstname);
            bodyFormData.append('community', this.state.commupk);
            bodyFormData.append('email', this.state.email);
            bodyFormData.append('city', this.state.city.value);

            addmembers(bodyFormData)
            .then(data => {
                if (!data || !data.success) {
                    swal({
                        icon: "error",
                        title: "Une erreur est survenue",
                        text: "Impossible d'ajouter un nouveau membre"
                    });
                    this.setState({ processing: false });
                    return;
                }
                swal({
                    icon: "success",
                    title: "Nouveau Membre Ajouté",
                    timer: 1500
                });
                this.hide();

                if (typeof this.callback === "function") {
                    this.callback(data.item);
                } else {
                    //Reload After Modify 
                    setTimeout(() => {
                        window.location.reload(true);
                    }, 1500);
                }
            })
            .catch(err => {
                swal({
                    icon: "error",
                    title: "Une erreur est survenue",
                    text: "Impossible d'ajouter un nouveau membre"
                });
                this.setState({ processing: false });
                //TODO
            });
        }

        else {
            bodyFormData.append('lastname', this.state.lastname);
            bodyFormData.append('firstname', this.state.firstname);
            bodyFormData.append('email', this.state.email);
            bodyFormData.append('city', this.state.city.value);

            editmembers(this.state.pk, bodyFormData)
            .then(data => {
                if (!data || !data.success) {
                    swal({
                        icon: "error",
                        title: "Une erreur est survenue",
                        text: "Impossible de modifier les informations du membre"
                    });
                    this.setState({ processing: false });
                    return;
                }
                swal({
                    icon: "success",
                    title: "Informations Modifiées",
                    timer: 1500
                });
                this.hide();

                console.log(this.props.callback);

                if (typeof this.callback === "function") {
                    this.callback(data.item);
                } else {
                    //Reload After Modify 
                    setTimeout(() => {
                        window.location.reload();
                    }, 1500);
                }
            })
            .catch(err => {
                swal({
                    icon: "error",
                    title: "Une erreur est survenue",
                    text: "Impossible de modifier les informations du membre"
                });
                this.setState({ processing: false });
                //TODO
            });
        }
    }

    render() {
        return (
            <Modal show={this.state.show} handleClose={this.hide} title={this.state.title} >
                <div className="card-inner">
                    <form onSubmit={this.handleFormSubmit} id="add_alert">
                        <div className="row gy-4">

                            <div className="form-group col-md">
                                <div className="">
                                    <label className="form-label labells"> Nom </label>
                                    <input type="text" name="lastname" onChange={this.handleChangeLastnameValue} value={this.state.lastname} className={`form-control ${this.state.lastname_has_changed && this.state.lastname.trim().length === 0 ? 'is-invalid' : ''} `} disabled={this.state.processing} />
                                    <small className={'invalid-feedback'}> Champ obligatoire </small>
                                </div>
                            </div>

                            <div className="form-group col-md">
                                <div className="form-control-wrap-outlined">
                                    <label className="form-label labells"> Prénom </label>
                                    <input type="text" name="firstname" onChange={this.handleChangeFirstnameValue} value={this.state.firstname} className={`form-control ${this.state.firstname_has_changed && this.state.firstname.trim().length === 0 ? 'is-invalid' : ''}`} disabled={this.state.processing} />
                                    <small className={'invalid-feedback'}> Champ obligatoire </small>
                                </div>
                            </div>

                        </div>

                        <div className="form-group">

                            <div className="form-control-wrap-outlined">
                                <label className="form-label labells"> Email </label>
                                <input type="email" name="email" onChange={this.handleChangeMailValue} value={this.state.email} className={`form-control ${this.state.email_has_changed && !this.state.emailIsValid ? 'is-invalid' : ''}`} disabled={this.state.processing} />
                                <small className={'invalid-feedback'}> Veuillez mettre une adresse mail valide </small>
                            </div>

                        </div>

                        <div className="form-group">

                            <div className="form-control-wrap-outlined">
                                <label className="form-label labells"> Ville </label>
                                <Async
                                    name="city"
                                    loadOptions={getCitiesSelectOptionsAsync}
                                    onChange={this.handleCityChanged}
                                    noOptionsMessage={() => { return "Tapez le nom de la ville ou son code postal"; }}
                                    value={this.state.city}
                                    defaultOptions={this.state.city ? [this.state.city] : null}
                                    isDisabled={this.state.processing}
                                />
                            </div>

                        </div>

                        <div className="form-group">
                            <button type="submit" className="btn btn-lg btn-success bg-gradient " id="Submit_btn" disabled={this.state.processing || !this.checkValidInput()}> {this.state.commun_name ? "Ajouter" : "Modifier"} </button>
                        </div>
                    </form>
                </div>
            </Modal>
        );
    }
}

export default MemberEditModal;