import React, { Component } from "react";
import { Link } from "react-router-dom";

class Navlink extends Component {
    render() {
        let { path, label } = this.props;
        const name = path ? path : "dashboard";
        path = path ? `/${path}` : "/";
        const active = window.location.pathname.endsWith(path);
        const classname = active ? "active" : "";

        return (
            <>
                <li id={name} className={`item ${classname}`}>
                    <Link className="link" to={path} disabled={active}> {label} </Link>
                    {active ? <hr className="nav-hr" /> : null}
                </li>
            </>
        );
    }
}

class Navbar extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    render() {
        return (
            <div className="nk-header-news d-none d-xl-block">
                <div className="nk-news-list">
                    <nav>
                        <ul className="breadcrumb">
                            <Navlink label="Dashboard" />
                            <Navlink path="historique" label="Historique des alertes" />
                            <Navlink path="communautes" label="Communautés" />
                            <Navlink path="categories" label="Catégories" />
                            <Navlink path="import" label="Import de fichiers" />
                        </ul>
                    </nav>
                </div>
            </div>
        );
    }
}

export default Navbar;