import React, { Component } from "react";
import 'react-custom-scroll/dist/customScroll.css';
import { Link } from "react-router-dom";
import Loading from "../../Loading";

export class Field {
  constructor(name, label, order_by_enabled = false, research_enabled = false, className = "text-left") {
    this.name = name;
    this.label = label;
    this.className = className;
    this.order_by_enabled = order_by_enabled;
    this.research_enabled = research_enabled;
    this.researchTimeoutID = undefined;
  }
}

export class GenericDataList extends Component {
  constructor(props, paginate_by = 10, order_by = null, disable_cache = false) {
    super(props);

    this.state = {
      is_loaded: false,
      page: 1,
      nb_pages: 0,
      is_first_page: true,
      is_last_page: true,
      paginate_by: paginate_by,
      order_by: order_by,
      research: {}
    };

    this.pages_data = null;
    this.disable_cache = disable_cache;

    this.go_to_previous = this.go_to_previous.bind(this);
    this.go_to_next = this.go_to_next.bind(this);
    this.go_to_page = this.go_to_page.bind(this);
    this.go_to_first = this.go_to_first.bind(this);
    this.go_to_last = this.go_to_last.bind(this);
    this.load_page = this.load_page.bind(this);
    this.parse_data = this.parse_data.bind(this);
    this.clear_current_page_cache = this.clear_current_page_cache.bind(this);
    this.clear_cache = this.clear_cache.bind(this);
    this.get_header_row = this.get_header_row.bind(this);
    this.get_research_row = this.get_research_row.bind(this);
    this.get_data_row = this.get_data_row.bind(this);
    this.order_by = this.order_by.bind(this);
    this.handle_research_changed = this.handle_research_changed.bind(this);
    this.loadData = null;
    this.columns = [];
  }

  componentDidMount(error) {
    this.load_page(1);
  }

  go_to_previous() {
    if (this.state.page === 1) {
      return;
    }
    this.go_to_page(this.state.page - 1);
  }

  go_to_next() {
    if (this.state.page === this.state.nb_pages) {
      return;
    }
    this.go_to_page(this.state.page + 1);
  }

  go_to_first() {
    if (this.state.page === 1) {
      return;
    }
    this.go_to_page(1);
  }

  go_to_last() {
    if (this.state.page === this.state.nb_pages) {
      return;
    }
    this.go_to_page(this.state.nb_pages);
  }

  go_to_page(page) {
    if (!this.disable_cache && this.pages_data[page - 1]) {
      this.setState({
        page: page,
        is_first_page: page === 1,
        is_last_page: page === this.state.nb_pages,
      });
      window.scrollTo(0, 0);
      return;
    }

    this.load_page(page);
  }

  order_by(field) {
    if (!field.name) {
      return;
    }

    if (this.state.order_by === field.name) {
      this.load_page(1, "-" + field.name);
    }

    else {
      this.load_page(1, field.name);
    }
  }

  get_research_data() {
    let research_inputs = document.querySelectorAll(".research-row input");
    let research = {};
    research_inputs.forEach((input) => {
      if (input.value) {
        research[input.dataset.fieldname + "-startswith"] = input.value;
      }
    });

    return research;
  }

  handle_research_changed() {
    if (typeof this.researchTimeoutID === 'number') {
      clearTimeout(this.researchTimeoutID);
    }

    this.researchTimeoutID = setTimeout(() => { this.load_page(1, null, this.get_research_data()); }, 250);
  }

  parse_data(data) {
    const nb_pages = Math.ceil(data['nb-total-results'] / data['paginate-by']);
    if (!this.state.is_loaded || this.state.nb_total_result !== data['nb-total-results'] || this.state.order_by !== data["order-by"]) {
      this.pages_data = [];
      for (let i = 0; i < nb_pages; ++i) {
        this.pages_data.push(null);
      }
    }

    let current_page = parseInt(data['page']);
    this.pages_data[current_page - 1] = data['items'];

    this.setState({
      is_loaded: true,
      nb_pages: nb_pages,
      nb_total_result: data['nb-total-results'],
      page: current_page,
      order_by: data['order-by'],
      is_first_page: data['page'] === 1,
      is_last_page: data['page'] === nb_pages,
    });
  }

  load_page(page, order_by = null, research = null) {
    if (!this.loadData) {
      return;
    }

    let params = { page: page, 'paginate-by': this.state.paginate_by };
    if (order_by && order_by !== this.state.order_by) {
      params['order-by'] = order_by;
    }
    else if (this.state.order_by) {
      params['order-by'] = this.state.order_by;
    }

    if (research !== null) {
      params = { ...params, ...research };
      this.setState({ research: research });
    } else {
      params = { ...params, ...this.state.research };
    }

    this.loadData(params).then(
      (data) => {
        if (data && data.success) {
          this.parse_data(data);
          window.scrollTo(0, 0);
        }
        else {
          //TODO
        }
      },
      (error) => {
        this.setState({
          is_loaded: true,
          error
        });
      }
    );
  }

  clear_current_page_cache() {
    this.clear_cache(this.state.page);
  }

  clear_cache(page = 0) {
    if (page) {
      this.pages_data[page - 1] = null;
    } else {
      for (let i in this.pages_data) {
        this.pages_data[i] = null;
      }
    }
  }

  get_header_row() {
    return <tr className="table_row">
      {
        this.columns.map((field, i) => {
          if (field.order_by_enabled && field.name) {
            let icon = null;
            if (this.state.order_by === field.name) {
              icon = <em className="icon ni ni-upword-alt-fill"></em>
            }

            else if (this.state.order_by === "-" + field.name) {
              icon = <em className="icon ni ni-downward-alt-fill"></em>
            }

            else {
              icon = <em className="icon ni ni-sort"></em>
            }

            return <th key={i} className={field.className + " sortable"} onClick={() => this.order_by(field)}>{icon}{field.label}</th>;
          }

          else {
            return <th key={i} className={field.className}>{field.label}</th>;
          }

        })
      }
    </tr>
  }

  get_research_row() {
    let research_enabled = false;
    this.columns.forEach((field) => {
      if (field.research_enabled && field.name) {
        research_enabled = true;
        return false;
      }
    });

    if (!research_enabled) {
      return <></>
    }

    return <div className="row gy-4 space">
      {
        this.columns.map((field, i) => {
          if (field.research_enabled && field.name) {
            return <div key={i} className={field.className + " research-row col-md "}>
              <div className="form-control-wrap">
                <div className="form-icon form-icon-right md">
                  <em className="icon ni ni-search"></em>
                </div>
                <input type="text" data-fieldname={field.name} onChange={this.handle_research_changed} className="form-control form-control-md form-control-outlined input_placeholder" placeholder={`rechercher par ${field.label}`} />
              </div>
            </div>;
          }
          else {
            return <th key={i}></th>;
          }
        })
      }
    </div>
  }

  get_data_row(data, index) {
    return null;
  }

  render() {

    if (!this.state.is_loaded) {
      return (
        <div className="details_recipients"> <Loading /> </div>
      );
    }

    let previous_btn, next_btn, first_btn, last_btn;
    if (this.state.is_first_page) {
      previous_btn = <li className="page-item disabled">
        <Link className="page-link" to={'#'}> Précedent </Link>
      </li>;

      first_btn = <li className="page-item active">
        <Link className="page-link" to={'#'}>1</Link>
      </li>;
    } else {
      previous_btn = <li className="page-item">
        <Link className="page-link" to={'#'} onClick={this.go_to_previous}> Précedent </Link>
      </li>;

      first_btn = <li className="page-item">
        <Link className="page-link" to={'#'} onClick={this.go_to_first}>1</Link>
      </li>;
    }

    if (this.state.is_last_page) {
      next_btn = <li className="page-item disabled">
        <Link className="page-link" to={'#'}> Suivant </Link>
      </li>;

      last_btn = <li className="page-item active">
        <Link className="page-link" to={'#'}> {this.state.nb_pages} </Link>
      </li>;
    } else {
      next_btn = <li className="page-item">
        <Link className="page-link" to={'#'} onClick={this.go_to_next}> Suivant </Link>
      </li>;

      last_btn = <li className="page-item">
        <Link className="page-link" to={'#'} onClick={this.go_to_last}> {this.state.nb_pages} </Link>
      </li>;
    }

    let page_btns = [];

    for (let i = Math.max(2, this.state.page - 2); i <= Math.min(this.state.nb_pages - 1, this.state.page + 2); ++i) {
      if (this.state.page === i) {
        page_btns.push(<li key={i} className="page-item active">
          <Link className="page-link" to={'#'}>{i}</Link>
        </li>
        );
      } else {
        page_btns.push(<li key={i} className="page-item">
          <Link className="page-link" to={'#'} onClick={() => this.go_to_page(i)}>{i}</Link>
        </li>);
      }
    }

    return (
      <div className="col-md-12">
        {this.get_research_row()}
        <div className="card card-preview table_generic">
          <div className="card-inner inac">
            <table className="table">
              <thead>
                {this.get_header_row()}
                {/**this.get_research_row()*/}
              </thead>
              <tbody>
                {this.pages_data[this.state.page - 1].map(this.get_data_row)}
              </tbody>
            </table>
          </div>
        </div>
        {
          this.state.nb_total_result > 0 ?
            <nav className="pagination-footer">
              <span className="justify-content-start resultats">
                <br />
                {this.state.nb_total_result} résultat{this.state.nb_total_result > 1 ? "s" : ""}
              </span>

              <span className="justify-content-end">
                <br />
                <ul className="pagination">
                  {previous_btn}
                  {first_btn}
                  {this.state.page > 4 ? <li className="page-item "> <span className="page-link"> <em className="icon ni ni-more-h"></em> </span></li> : null}
                  {page_btns.map((element, i) => { return (element); })}
                  {this.state.page < this.state.nb_pages - 3 ? <li className="page-item blank "> <span className="page-link"> <em className="icon ni ni-more-h"></em> </span> </li> : null}
                  {this.state.nb_pages > 1 ? last_btn : null}
                  {next_btn}
                </ul>
              </span>
            </nav>
            : null
        }
      </div>
    );
  }
}

export default GenericDataList