import React from 'react';
import { useAuth } from 'react-oauth2-pkce';
import config from './config';
import { BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import Erreur from './components/Errors/Error404';
import Dashboard from './components/Pages/Dashboard/Dashboard';
import './App.css';
import History from './components/Pages/History/History';
import Communities from './components/Pages/Communities/Communities';
import Categories from './components/Pages/Categories/Categories';
import Import from './components/Pages/Import/Import';

function App() {
    const { authService } = useAuth();
    const Login = async () => authService.login();
    const Logout = async () => authService.logout();

    if (authService.isPending()) {
      if (!(authService.getCodeFromLocation(window.location))) { Login(); return; }

      return (
        <div className="nk-body bg-white npc-general pg-auth">
            <div className="nk-app-root">
                {/* <!-- main @s --> */}
                <div className="nk-main">
                    {/* <!-- wrap @s --> */}
                    <div className="nk-wrap nk-wrap-nosidebar">
                        {/* <!-- content @s --> */}
                        <div className="nk-content ">
                            <div className="nk-block nk-block-middle nk-auth-body  wide-xs">
                                <div className="brand-logo pb-4 text-center">
                                    <h4 className="nk-block-title"> Connection en cours ... </h4>
                                    <button className="btn btn-primary btn-block" onClick={() => {Logout(); }}> Retourner à la page de connection </button>
                                </div>
                            </div>
                        </div>
                        {/* <!-- wrap @e --> */}
                    </div>
                    {/* <!-- content @e --> */}
                </div>
                {/* <!-- main @e --> */}
            </div>
        </div>
      );
    }

    if (!authService.isAuthenticated()) { 
      Login();
      return null;
    }

    const token = authService.getAuthTokens();

    if(token.expires_at <= Date.now()) {
      authService.armRefreshTimer(token.refresh_token, token.expires_in);
    } 
    else {
      setTimeout(() => { authService.armRefreshTimer(token.refresh_token, token.expires_in); }, token.expires_at - Date.now());
    }

    return (
      <Router basename={config.route}>
        <Switch>

          <Route exact path='/' >
            <Dashboard />
          </Route>

          <Route path='/historique'>
            <History />
          </Route>

          <Route path='/communautes'>
            <Communities />
          </Route>

          <Route path='/categories'>
            <Categories />
          </Route>

          <Route path='/import'>
            <Import />
          </Route>

          <Route path='/*'>
            <Erreur />
          </Route>

        </Switch>
      </Router>
    );
}

export default App;
