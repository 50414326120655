import React, { Component } from "react";
import { Link } from "react-router-dom";
import logo from '../img/logo-alerte-70-header.png';
import Navbar from "./Navbar";
//import Modal from "./Pages/Generics/Modal";
import AlertModal from "./Pages/Generics/AlertModal";

class Mainheader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
        };
        this.hideModal = this.hideModal.bind(this);
        this.showModal = this.showModal.bind(this);
    }

    //Show Modal
    showModal() {
        this.setState({ show: true });
        this.showHideName = "display-block";
    };

    /** Modification_HideModal */

    //hide Modal
    hideModal() {
        this.setState({ show: false });
        this.showHideName = "display-none";
        setTimeout(() => document.getElementById('add_alert'), 500);
    };
    
    render() {
        return (
            <div className="nk-header nk-header-fixed is-light">
                <div className="container-fluid">
                    <div className="nk-header-wrap">

                        <div className="nk-menu-trigger d-xl-none ml-n1" style={{ display: 'none' }}>
                            <Link to="/" className="nk-nav-toggle nk-quick-nav-icon" data-target="sidebarMenu">
                                <em className="icon ni ni-menu" />
                            </Link>
                        </div>

                        <div className="nk-header-brand d-xl-none">
                            <Link to={"/"} className="logo-link">
                                <img className="logo-light logo-img" src={logo} srcSet={logo} width={150} alt="logo"/>
                                <img className="logo-dark logo-img" src={logo} srcSet={logo} width={150} alt="logo-dark" />
                            </Link>
                        </div>

                        {/* <!-- .nk-header-brand --> */}
                        <Navbar/>
                        
                        {/* <!-- .nk-header-news --> */}
                        <div className="nk-header-tools">
                            {/* <!-- .nk-quick-nav --> */}
                            <ul className="nk-quick-nav">
                                {/* <!-- .dropdown --> */}
                                <li className="dropdown user-dropdown">

                                    <div className="user-toggle">
                                        <div className="user-info d-none d-md-block">
                                            <div className="btn btn-primary create_alert" type="button" onClick={this.showModal}>
                                                <em className="icon ni ni-plus"></em>  &nbsp; Créer une alerte </div>
                                        </div>
                                    </div>

                                    <AlertModal show={this.state.show} handleClose={this.hideModal} title="Créer une alerte"/>
                                </li>
                                {/* <!-- .dropdown --> */}
                            </ul>
                            {/* <!-- .nk-quick-nav --> */}
                        </div>
                        {/* <!-- .nk-header-tools --> */}
                    </div>
                    {/* <!-- .nk-header-wrap --> */}
                </div>
                {/* <!-- .container-fliud --> */}
            </div>
        );
    }
}

export default Mainheader;