import React, { Component } from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';


class CancelTimer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            progress_value:100
        };

        this.timeoutID = undefined;
        this.start_ts = 0;
        this.start = this.start.bind(this);
        this.cancel = this.cancel.bind(this);
        this.update = this.update.bind(this);
    }

    update() {
        let progress = (Date.now() - this.start_ts) / this.props.delai;
        if (progress > 1 || !this.state.processing) { return; }

        this.setState({progress_value: parseInt((1-progress)*100)});
        requestAnimationFrame(this.update);
    }

    start() {
        this.setState({ processing: true });
        this.LoaderTimeoutID = setTimeout(this.props.onTimeout, this.props.delai);
        this.start_ts = Date.now();

        requestAnimationFrame(this.update);
    }

    cancel(e) {
        if (typeof this.LoaderTimeoutID === "number") {
            clearTimeout(this.LoaderTimeoutID);
        }

        this.setState({ progress_value:100 });
    }

    async componentDidMount() {
        if (this.props.running) {
            this.start();
        }
    }

    async componentDidUpdate(prevProps, prevState) {
        if (!prevProps.running && this.props.running) {
            this.start();
        } else if (prevProps.running && !this.props.running) {
            this.cancel();
        }
    }

    render() {
        return (
            <>
                <CircularProgressbar
                    value={this.state.progress_value}
                    strokeWidth={15}
                    styles= {{path: {
                        // Path color
                        stroke: `rgba(255, 255, 255, 1)`,
                        // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                        strokeLinecap: 'round',
                        // Customize transition animation
                        transition: 'none',
                        },
                        trail: {
                        // Trail color
                        stroke: `rgba(0, 0, 0, 0)`,
                        },
                    }}
                />
            </>
        );
    }
}

export default CancelTimer;