import React, { Component } from "react";
import { Link } from 'react-router-dom';
import 'react-custom-scroll/dist/customScroll.css';
import { getCommunities } from '../../webServices'
import { GenericDataList, Field } from '../Generics/GenericDataList'
import config from '../../../config';


class CommunityRow extends Component {
  constructor(props) {
    super(props);
    this.state = { community: this.props.community };
    this.onEdited = this.onEdited.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.community.pk !== prevProps.community.pk) {
      this.setState({ community: this.props.community });
    }
  }

  onEdited(community) {
    this.setState({ community: community });
    if (typeof this.props.onDataChanged === "function") {
      this.props.onDataChanged();
    }
  }

  render() {
    const { community } = this.state;

    let img = <img src={`${config.server.replace(/\/$/g, '')}${community.image_url}`} className="community-img" alt="img_commu" />;

    let action_btn = <>
      <Link to="#" className="btn btn-trigger btn-icon" onClick={() => { this.props.editCommunity(community, this.onEdited); }} title="Modifier le libelle"> <em className="icon ni ni-edit-alt edito"></em> </Link>
      <Link to="#" className="btn btn-trigger btn-icon" onClick={() => { this.props.sendCommunityEmailReminder(community, this.onEdited); }} title="Relance email d'inscription"> <em className="icon ni ni-emails edito"></em> </Link>
    </>;

    return (
      <tr>

        <td className="project-title">
          <div className="user-avatar">
            <span> {img} </span>
          </div>
          <Link to='#' className="project-info" onClick={() => { this.props.goToCommunityMember(community.pk, community.name) }}> {community.name} </Link>
        </td>

        <td className="text-center">
        {/*{community.nb_enabled_members} membre{community.nb_enabled_members > 1 ? 's' : ''}*/}
        {/*{community.nb_active_people} membre{community.nb_active_people > 1 ? 's' : ''} actif{community.nb_active_people > 1 ? 's' : ''} / {community.nb_enabled_members} membre{community.nb_enabled_members > 1 ? 's' : ''}*/}
        {community.nb_active_members} membre{community.nb_active_members > 1 ? 's' : ''} actif{community.nb_active_members > 1 ? 's' : ''}
        </td>
        <td className="text-center">
          {community.nb_enabled_members} membre{community.nb_enabled_members > 1 ? 's' : ''}
        </td>

        <td className="text-center">
          {action_btn}
        </td>

      </tr>
    );
  }
}

class ListCommunities extends GenericDataList {
  constructor(props) {
    super(props, 10);
    this.loadData = getCommunities;
    this.columns = [new Field("name", "Communauté", true, true), new Field("", " ", " ", false, false, "text-center"), new Field("", " ", " ", false, false, "text-center"), new Field("", "Action", "", false, false, "text-center")];
    this.onDataChanged = this.onDataChanged.bind(this);
  }

  onDataChanged() {
    this.clear_cache();
  }

  get_data_row(data, index) {
    return (
      <CommunityRow
        key={index}
        community={data}
        goToCommunityMember={this.props.goToCommunityMember}
        editCommunity={this.props.editCommunity}
        sendCommunityEmailReminder={this.props.sendCommunityEmailReminder}
        archiveCommunity={this.props.archiveCommunity}
        onDataChanged={this.onDataChanged}
      />
    );
  }
}

export default ListCommunities