import React, { Component } from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement } from 'chart.js';
import Loading from "../../../Loading";
import config from '../../../../config';
import { getOpeningbyCommunity, getAccesToken } from '../../../webServices';

ChartJS.register(CategoryScale, LinearScale, BarElement, ArcElement, Title, Tooltip, Legend,);

class OpeningWidget extends Component {
  constructor(props) {
    super(props);
    this.state = {
      item: {
        nb_people_affected: 0,
        nb_readings: 0
      },
      chartData: {
        datasets: [],
        labels: []
      },
      is_loaded: false
    };
    
    this.ws = null;
    this.wsConnect = this.wsConnect.bind(this);
    this.formatDataOpening = this.formatDataOpening.bind(this);
  }

  wsConnect() {
    this.wsClose();
    this.ws = new WebSocket(`${config.websocket_service}alert/${this.props.alert.id}/readingsstats?subscribe-broadcast&access_token=${getAccesToken()}`);

    this.ws.onopen = function () {
    };

    this.ws.onmessage = (e) => {
      let res = JSON.parse(e.data);
      if (res.widget === "readingsstats") {
        this.setState({
          ...this.formatDataOpening(res.data)
        });
      }
    };

    let context = this;
    this.ws.onerror = function (e) {
      console.error("ws error: ", e);
      context.wsClose();
    };

    //
    this.ws.onclose = function (e) {
    };
  }

  wsClose() {
    if (this.ws && this.ws.readyState === 1) {
      this.ws.close();
    }
  }

  async componentDidMount() {
    this.wsConnect();

    //Stat Reading by Community
    const data = await getOpeningbyCommunity(this.props.alert.id);
    this.setState({
      is_loaded: true,
      ...this.formatDataOpening(data)
    });
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.alert.id !== this.props.alert.id) {
      this.wsConnect();

      const data = await getOpeningbyCommunity(this.props.alert.id);
      this.setState({
        is_loaded: true,
        ...this.formatDataOpening(data)
      });
    }
  }

  componentWillUnmount() {
    this.wsClose();
  }

  formatDataOpening(data) {
    const rated_by = Math.round(data.nb_readings / data.nb_people_affected * 100 || 0);
    var colors = ['#50B5FF'];
    var labels = ['Lu'];
    var data1 = [rated_by];
    var data2 = [0];

    //faire la somme des communautées qui on lu
    var sum = 0;
    for (const [key, value] of Object.entries(data.readings_by_community)) {
      let community = null;
      for (let i in this.props.alert.communities) {
        if (this.props.alert.communities[i].slug === key) {
          community = this.props.alert.communities[i];
          break;
        }
      }
      if (!community) {
        continue;
      }
      sum += value
      colors.push(community.color);
      labels.push(community.name);
    }

    colors.push('#F1F1F5');
    labels.push('Non lu');

    for (const [/**cle*/, valeur] of Object.entries(data.readings_by_community)) {
      data1.push(0);
      data2.push(Math.round(valeur / sum * rated_by));
    }

    data1.push(100 - rated_by);
    data2.push(100 - rated_by);

    var chartData = {
      datasets: [{
        data: data2,
        backgroundColor: colors
      },
      {
        data: data1,
        backgroundColor: colors
      }],
      labels: labels,
    };

    return {
      item: {
        nb_readings: data.nb_readings,
        nb_people_affected: data.nb_people_affected
      },
      readings_by_community: data.readings_by_community,
      rated_by: rated_by,
      chartData: chartData
    }
  }

  render() {
    //style doughtnut
    const styles = {
      doughnut: {
        position: 'relative',
      },
    }

    return (
      <div className="card card-bordered truc_border_opening eval style_eval ">
        <div className="card-inner d-flex flex-column special_width">
          <div className="card-title-group ouverture">
            <div className="card-title text-center center_sp" style={styles.doughnut}>
              <h6 className="title nbr_opening"> Nombre d'ouvertures </h6>
              <Doughnut className="dought"
                data={this.state.chartData}
                options={{
                  maintainAspectRatio: true,
                  rotation: -90,
                  radius: 80,
                  circumference: 180,
                  cutout: 60,
                  //cutout: 80
                  plugins: {
                    legend: {
                      display: false
                    }
                  }
                }}
              />

              <div className="card-inner text-center text_truc">

                {this.state.is_loaded ? null : <Loading />}

                <div className="counter_detail" style={styles.rated} id='id_responses'> {this.state.rated_by} % </div>
                <span className="text-center" style={styles.opening}> Pourcentage d’ouvertures <br /> en fonction des personnes atteintes </span>

              </div>

            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default OpeningWidget;
